import { useEffect, useState } from "react";
import { useLazyQuery, useMutation } from "@apollo/react-hooks";
import { useDispatch, useSelector } from "react-redux";
import { updateHotels, updateServerBookmarks, getHotels } from "../actions/hotel";
import { GET_HOTELS, GET_HOTEL, GET_CONTENT, GET_PORTAL } from "./queries/hotel";
import { SIGN_IN_ROOM } from "./mutations/hotel";
import handleGraphqlError from "./handleGraphqlError";
import { getToken } from "../actions/config";
import { getToken as getUserToken, getUser } from "../actions/user";
import { deleteRoom } from "../modules/rooms/store";

export const useHotels = ({ autoload, ref }) => {
    const dispatch = useDispatch();
    const appToken = useSelector(getToken);
    const userToken = useSelector(getUserToken);
    const cachedHotels = useSelector(getHotels);
    const currentUser = useSelector(getUser);
    const bookmarks = useSelector((state) =>
        currentUser && state.user.bookmarks && state.user.bookmarks[currentUser]
            ? state.user.bookmarks[currentUser].data
            : null
    );

    const [loadHotels, { loading, error }] = useLazyQuery(GET_HOTELS.query, {
        fetchPolicy: "cache-and-network",
        onCompleted: (data) => {
            if (data && data.getProjects && data.getProjects.results) {
                const bookmarks = [];
                data.getProjects.results.forEach((value) => {
                    if (value.isBookmarked) {
                        bookmarks.push(value.ref);
                    }
                });
                dispatch(updateServerBookmarks(bookmarks));
                dispatch(updateHotels(data.getProjects.results));
            }
        },
        onError: (error) => {
            handleGraphqlError(error, dispatch, appToken, userToken);
        },
    });

    useEffect(() => {
        if (autoload) {
            loadHotels({ variables: GET_HOTELS.defaultVars });
        }
    }, []);

    const getHotelsFn = (params) => {
        if (!params) {
            params = { variables: GET_HOTELS.defaultVars };
        }
        return loadHotels(params);
    };

    var result = cachedHotels;
    if (ref) {
        const found = cachedHotels ? cachedHotels.filter((h) => h && h.ref === ref) : null;
        result = found && found.length > 0 ? found[0] : null;
    }

    return {
        getHotels: getHotelsFn,
        loading: cachedHotels ? false : loading,
        error: cachedHotels ? null : error,
        hotels: result,
        bookmarks,
    };
};

export const useSignInRoom = (callbacks) => {
    const [signInRoomResponse, setSignInRoom] = useState(null);
    const userToken = useSelector(getUserToken);
    const appToken = useSelector(getToken);
    const dispatch = useDispatch();
    const [signInRoom, { loading, error }] = useMutation(SIGN_IN_ROOM.query, {
        onCompleted: (data) => {
            if (data && data.signInRoom) {
                setSignInRoom(data.signInRoom);
            }
            if (callbacks && callbacks.onCompleted) {
                callbacks.onCompleted(data);
            }
        },
        onError: (error) => {
            handleGraphqlError(error, dispatch, appToken, userToken);
            if (callbacks && callbacks.onError) {
                callbacks.onError(error);
            }
        },
    });
    return { signInRoom, loading, error, signInRoomResponse };
};

export const useHotel = (callbacks) => {
    const userToken = useSelector(getUserToken);
    const appToken = useSelector(getToken);
    const dispatch = useDispatch();
    const [hotel, { loading, error, data, refetch, variables }] = useLazyQuery(GET_HOTEL.query, {
        fetchPolicy: "no-cache",
        nextFetchPolicy: "no-cache",
        onCompleted: (data) => {
            if (callbacks && callbacks.onCompleted) {
                callbacks.onCompleted(data);
            }
        },
        onError: (error) => {
            if (
                error &&
                (error.message === "response status error: invalid password" ||
                    error.message === "not found error: room not found")
            ) {
                dispatch(deleteRoom({ projectRef: variables.ref, number: variables.roomNumber }));
            } else {
                handleGraphqlError(error, dispatch, appToken, userToken);
            }
            handleGraphqlError(error, dispatch, appToken, userToken);
            if (callbacks && callbacks.onError) {
                callbacks.onError(error);
            }
        },
    });

    if (data && data.getProject && data.getProject.widgets && data.getProject.widgets.length > 0) {
        data.getProject.widgets.map((w) => {
            if (!w.style || typeof w.style === "string") {
                if (w) w.style = (w.style ? JSON.parse(w.style) : null) || {};
            }
            return w;
        });
    }
    return { hotel, loading, error, data, refetch };
};

export const usePortal = (callbacks) => {
    const userToken = useSelector(getUserToken);
    const appToken = useSelector(getToken);
    const hasToken = userToken || appToken ? true : false;
    const dispatch = useDispatch();
    const [loadPortal, { loading, error, data, refetch }] = useLazyQuery(GET_PORTAL.query, {
        fetchPolicy: "no-cache",
        nextFetchPolicy: "no-cache",
        onCompleted: (data) => {
            if (callbacks && callbacks.onCompleted) {
                callbacks.onCompleted(data);
            }
        },
        onError: (error) => {
            handleGraphqlError(error, dispatch, appToken, userToken);
            if (callbacks && callbacks.onError) {
                callbacks.onError(error);
            }
        },
    });

    if (data && data.getProject && data.getProject.portal) {
        if (
            data.getProject.portal.desktop &&
            data.getProject.portal.desktop.widgets &&
            data.getProject.portal.desktop.widgets.length > 0
        ) {
            data.getProject.portal.desktop.widgets.map((w) => {
                if (!w.style || typeof w.style === "string") {
                    if (w) w.style = (w.style ? JSON.parse(w.style) : null) || {};
                }
                return w;
            });
        }
        if (
            data.getProject.portal.mobile &&
            data.getProject.portal.mobile.widgets &&
            data.getProject.portal.mobile.widgets.length > 0
        ) {
            data.getProject.portal.mobile.widgets.map((w) => {
                if (!w.style || typeof w.style === "string") {
                    if (w) w.style = (w.style ? JSON.parse(w.style) : null) || {};
                }
                return w;
            });
        }
    }

    return { loadPortal: hasToken ? loadPortal : () => {}, loading, error, data, refetch };
};

export const useContent = (callbacks) => {
    const userToken = useSelector(getUserToken);
    const appToken = useSelector(getToken);
    const dispatch = useDispatch();
    const [content, { loading, error, data, refetch, variables }] = useLazyQuery(GET_CONTENT.query, {
        fetchPolicy: "no-cache",
        nextFetchPolicy: "no-cache",
        onCompleted: (data) => {
            if (callbacks && callbacks.onCompleted) {
                callbacks.onCompleted(data);
            }
        },
        onError: (error) => {
            if (
                error &&
                (error.message === "response status error: invalid password" ||
                    error.message === "not found error: room not found")
            ) {
                dispatch(deleteRoom({ projectRef: variables.hotelRef, number: variables.roomNumber }));
            } else {
                handleGraphqlError(error, dispatch, appToken, userToken);
            }
            if (callbacks && callbacks.onError) {
                callbacks.onError(error);
            }
        },
    });

    if (data && data.getContent && data.getContent.widgets && data.getContent.widgets.length > 0) {
        data.getContent.widgets.map((w) => {
            if (!w.style || typeof w.style === "string") {
                if (w) w.style = (w.style ? JSON.parse(w.style) : null) || {};
            }
            return w;
        });
    }

    return { content, loading, error, data, refetch };
};
