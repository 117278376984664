import React, { useState, useEffect } from "react";
import { Link, Redirect, useParams } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import { useTranslation } from "react-i18next";
import Header from "../components/Header";
import Tabs from "../components/Tabs";
import imageHelper from "../assets/images/addroom-help.png";
import swal from "@sweetalert/with-react";
import AddRoomAlert from "../components/AddRoom";
import { getSelectedHotel, setSelectedHotel, setSelectedRoom } from "../actions/hotel";
import { useHotels } from "../graphql/useHotels";
import Loading from "../components/Loading";
import useAddRoom from "../hooks/useAddRoom";
import { markdown } from "utils/others";

const AddRoom = () => {
    const { t } = useTranslation();
    const dispatch = useDispatch();
    const { project: paramProject } = useParams();
    const searchParams = new URLSearchParams(window.location.search);
    const paramRoom = searchParams ? searchParams.get("room") : null;
    const paramCode = searchParams ? searchParams.get("code") : null;

    const project = useSelector(getSelectedHotel);

    const [redirectTo, setRedirectTo] = useState("");
    const { loading: loadingHotels } = useHotels({ autoload: true });
    const { addRooms, loading: addingRoom } = useAddRoom({ project });

    const switchingProject = paramProject && project?.ref !== paramProject;

    if (switchingProject) {
        dispatch(setSelectedHotel(paramProject));
    }

    const onRoomAdded = (number, alertMode) => {
        return (success) => {
            if (alertMode) {
                swal.close();
            }
            if (success) {
                dispatch(setSelectedRoom(number));
                setRedirectTo("/site");
            }
        };
    };

    const onError = (alertMode) => {
        return (e) => {
            console.error(e);
            if (alertMode) {
                swal.close();
            }
        };
    };

    const handleForm = async (data) => {
        addRooms([{ number: data.room, code: data.code }], { check: true, checkWiFi: true })
            .then(onRoomAdded(data.room, true))
            .catch(onError(true));
    };

    useEffect(() => {
        if (paramRoom && paramCode) {
            window.history.replaceState({}, "", window.location.pathname);
            addRooms([{ number: paramRoom, code: paramCode }], { check: true, checkWiFi: true })
                .then(onRoomAdded(paramRoom, false))
                .catch(onError(false));
        }
    }, []);

    if (!switchingProject && !project) {
        return <Redirect push to="/site/list" />;
    }

    if (redirectTo) {
        return <Redirect to={redirectTo} />;
    }

    const enterCode = () => {
        swal({
            className: "custom",
            button: false,
            content: (
                <div>
                    <AddRoomAlert
                        onCancel={() => {
                            swal.close();
                        }}
                        onSuccess={handleForm}
                    />
                </div>
            ),
        });
    };

    return (
        <>
            {switchingProject || loadingHotels ? (
                <Loading />
            ) : (
                <section className={"basic has-top" + (addingRoom ? "" : " has-tabs")}>
                    <Header
                        title={t("add room")}
                        backAction={
                            addingRoom
                                ? null
                                : () => {
                                      setRedirectTo("/site");
                                  }
                        }
                    />
                    <section>
                        {addingRoom ? (
                            <Loading />
                        ) : (
                            <>
                                <p>{markdown(t("add room info"))}</p>
                                <img src={imageHelper} alt="TV example" className="max-w-full mx-auto my-10" />

                                <footer>
                                    <button
                                        onClick={() => {
                                            enterCode();
                                        }}
                                        className="text-accent font-bold text-lg w-full mb-5"
                                    >
                                        {t("enter password manually")}
                                    </button>

                                    <Link
                                        to="addroom/qr"
                                        onClick={() => {
                                            console.log("Hola");
                                        }}
                                        className="btn btn-accent text-lg w-full mb-3"
                                    >
                                        {t("scan qr")}
                                    </Link>
                                </footer>
                            </>
                        )}
                    </section>
                    {addingRoom ? null : <Tabs />}
                </section>
            )}
        </>
    );
};

export default AddRoom;
