import Header from "components/Header";
import React, { useEffect, useState, useRef } from "react";
import { useTranslation } from "react-i18next";
import TextInput from "components/inputs/TextInput";
import SelectInput from "components/inputs/SelectInput";
import { UsePrecheckinSettingsStayBookings } from "graphql/stays";
import InputDate from "components/inputs/InputDate";
import SelectorButton from "components/inputs/SelectorButton";
import isoCountries from "utils/countries";
import SaveButton from "./SaveButton";
import isEmail from "validator/lib/isEmail";
import { useDispatch, useSelector } from "react-redux";
import { useHistory, useParams } from "react-router-dom/cjs/react-router-dom.min";
import { getSelectedHotel } from "actions/hotel";
import { useAddStayBookingGuest } from "graphql/stays";
import Loading from "components/Loading";
import { useUpdateStayBookingGuest } from "graphql/stays";
import {
    getInfoFromData,
    calculateYearsSince,
    extractEnabledFields,
    calculateIsAChildGuest,
    filterValidFieldsWithValue,
    updateDataFieldsWithGuestInfo,
    parseValidFieldsToMutationVars,
    INPUTS_FORM_GENERATOR,
} from "./utils";

import InputTel from "components/inputs/InputTel";
import NationalitiesSearch from "views/NationalitiesSearch";
import { getStaysBookingsGuestOrders, setStaysBookingsGuestsOrder } from "actions/stays";
import { setIdAtIndexStaysBookingGuestsOrder } from "./utils";
import { useUpdateStayBooking } from "graphql/stays";

const FormGuestPrecheckin = () => {
    const { t } = useTranslation();

    const project = useSelector(getSelectedHotel);
    const { code, id, number } = useParams();
    const history = useHistory();
    const dispatch = useDispatch();
    const staysBookingGuestsOrder = useSelector(getStaysBookingsGuestOrders);

    const [data, setData] = useState({});
    const [dataFields, setDataFields] = useState({});
    const [enabledFields, setEnabledFields] = useState({});
    const [loading, setLoading] = useState(true);
    const renderWithGuestData = useRef(false);
    const [saveDisabled, setSaveDisabled] = useState(false);
    const [nationalitiesSearch, setNationalitiesSearch] = useState(false);

    const {
        guestIsFull,
        numberOfRooms,
        numberOfGuests,
        documentTypeOptions,
        maximumAgeOfChildren,
        requestDataFromChildren,
        preCheckInSettingsFields,
        preCheckInSettingsFieldsChildren,
        onModificationAvailable,
        guest,
    } = getInfoFromData(data, id);
    const fields = preCheckInSettingsFields;
    const fieldsChidlren = preCheckInSettingsFieldsChildren;
    const ageGuest = dataFields?.birthdate?.value !== null ? calculateYearsSince(dataFields?.birthdate?.value) : null;
    const isAChildGuest = calculateIsAChildGuest(ageGuest, maximumAgeOfChildren, requestDataFromChildren);
    const {
        query: queryCreate,
        error: errorCreate,
        result: resultCreate,
        resultId: resultCreateId,
    } = useAddStayBookingGuest({ hotelRef: project?.ref, stayBookingCode: code });
    const {
        query: queryUpdate,
        error: errorUpdate,
        result: resultUpdate,
        resultId: resultUpdateId,
    } = useUpdateStayBookingGuest({
        hotelRef: project?.ref,
        stayBookingCode: code,
        guestID: id !== "new" ? Number(id) : 0,
    });

    const { query: queryUpdatePendingChanges } = useUpdateStayBooking({ hotelRef: project?.ref, code: code });

    const INPUTS_FORM = INPUTS_FORM_GENERATOR({ numberOfRooms, t, isoCountries, documentTypeOptions });

    useEffect(() => {
        if (
            (guestIsFull === true && id === "new") ||
            resultCreate === true ||
            errorCreate ||
            errorUpdate ||
            resultUpdate
        ) {
            history.push(`/stays/details/${code}/precheckin/step/2`);
        }

        const handleGuestOrderUpdate = (resultId) => {
            if (staysBookingGuestsOrder[code]) {
                dispatch(
                    setStaysBookingsGuestsOrder({
                        code: code,
                        arrGuestsOrder: setIdAtIndexStaysBookingGuestsOrder(
                            staysBookingGuestsOrder[code],
                            number,
                            resultId
                        ),
                        numberOfGuests: numberOfGuests,
                    })
                );
            }
        };
        if (resultUpdate) {
            handleGuestOrderUpdate(resultUpdateId);
        }

        if (resultCreate) {
            handleGuestOrderUpdate(resultCreateId);
        }
    }, [guestIsFull, resultCreate, resultUpdate, errorCreate, errorUpdate]);

    useEffect(() => {
        if (fields && fieldsChidlren && numberOfRooms > 0 && !renderWithGuestData.current) {
            const enabledFields = extractEnabledFields(
                fields,
                isAChildGuest,
                fieldsChidlren,
                dataFields,
                numberOfRooms
            );
            const adultEnabledFields = extractEnabledFields(fields, false, fieldsChidlren, dataFields, numberOfRooms);
            const childEnabledFields = extractEnabledFields(fields, true, fieldsChidlren, dataFields, numberOfRooms);
            setEnabledFields({ adultEnabledFields, childEnabledFields });
            if (guest) {
                setDataFields(updateDataFieldsWithGuestInfo(guest, enabledFields, numberOfRooms));
            } else {
                setDataFields(enabledFields);
            }
            renderWithGuestData.current = true;
        }
    }, [fields, isAChildGuest, fieldsChidlren, guest, numberOfRooms]);

    useEffect(() => {
        if (dataFields?.email?.error) {
            setSaveDisabled(true);
        } else {
            setSaveDisabled(false);
        }
    }, [dataFields]);

    const inputForm = ({ type, key, options, placeholder, initialOptionSelected, name }) => {
        const shouldRenderInput = isAChildGuest
            ? enabledFields?.childEnabledFields?.[key]?.enabled ||
              (enabledFields?.adultEnabledFields?.[key]?.enabled && !enabledFields?.childEnabledFields?.[key]?.enabled)
            : enabledFields?.adultEnabledFields?.[key]?.enabled;

        const childGuestDisabled = isAChildGuest && !enabledFields?.childEnabledFields?.[key]?.enabled;
        return (
            <div className={`${shouldRenderInput ? " mb-4 w-full" : ""}`}>
                {type === "text" && shouldRenderInput ? (
                    <TextInput
                        key={key}
                        disabled={childGuestDisabled}
                        value={dataFields?.[key]?.value}
                        isRequired={dataFields?.[key]?.required}
                        onChange={(e) => {
                            if (key === "email") {
                                if (!isEmail(e.target.value)) {
                                    setDataFields({
                                        ...dataFields,
                                        email: {
                                            ...dataFields?.email,
                                            value: e.target.value,
                                            error: true,
                                            errorMsg: t("email-not-valid"),
                                        },
                                    });
                                } else {
                                    setDataFields({
                                        ...dataFields,
                                        email: {
                                            ...dataFields?.email,
                                            value: e.target.value,
                                            error: false,
                                            errorMsg: "",
                                        },
                                    });
                                }
                            } else {
                                setDataFields({
                                    ...dataFields,
                                    [key]: { ...dataFields?.[key], value: e.target.value },
                                });
                            }
                        }}
                        error={dataFields?.[key]?.error}
                        errorMsg={dataFields?.[key]?.errorMsg}
                        placeholder={placeholder || key}
                        name={name || key}
                    />
                ) : null}
                {type === "select" && shouldRenderInput ? (
                    <SelectInput
                        disabled={childGuestDisabled}
                        value={
                            key === "nationality" ? dataFields?.[key]?.value?.toLowerCase() : dataFields?.[key]?.value
                        }
                        onChange={(value) => {
                            const valueId = value?.[0]?.id;

                            setDataFields({
                                ...dataFields,
                                [key]: { ...dataFields?.[key], value: valueId },
                            });
                        }}
                        name={name || key}
                        isRequired={dataFields?.[key]?.required}
                        placeholder={placeholder || key}
                        options={options}
                        onClick={
                            key === "nationality"
                                ? () => {
                                      setNationalitiesSearch(true);
                                  }
                                : null
                        }
                    />
                ) : null}
                {type === "date" && shouldRenderInput ? (
                    <InputDate
                        disabled={childGuestDisabled}
                        value={dataFields?.[key]?.value}
                        onChange={(value) => {
                            setDataFields({
                                ...dataFields,
                                [key]: { ...dataFields?.[key], value },
                            });
                        }}
                        isRequired={dataFields?.[key]?.required}
                        name={name || key}
                    />
                ) : null}
                {type === "selector" && shouldRenderInput ? (
                    <SelectorButton
                        disabled={childGuestDisabled}
                        name={key}
                        isRequired={dataFields?.[key]?.required}
                        initialOptionSelected={dataFields?.[key]?.value || initialOptionSelected}
                        onChange={(value) => {
                            setDataFields({
                                ...dataFields,
                                [key]: { ...dataFields?.[key], value },
                            });
                        }}
                        error={dataFields?.[key]?.error}
                        options={options}
                    />
                ) : null}
                {type === "tel" && shouldRenderInput ? (
                    <InputTel
                        value={dataFields?.[key]?.value}
                        isRequired={dataFields?.[key]?.required}
                        disabled={childGuestDisabled}
                        onChange={(value) => {
                            setDataFields({
                                ...dataFields,
                                [key]: { ...dataFields?.[key], value },
                            });
                        }}
                    />
                ) : null}
            </div>
        );
    };

    return (
        <>
            {!nationalitiesSearch ? (
                <section className="basic has-tabs has-top">
                    {loading ? (
                        <div className=" w-full h-full absolute top-0 left-0 bg-white z-30 ">
                            <Loading />
                        </div>
                    ) : null}
                    <Header
                        back={`/stays/details/${code}/precheckin/step/2`}
                        title={`${t("guest")} ${Number(number) + 1}`}
                    />
                    <section className=" ">
                        <div className=" flex w-full space-x-4 ">
                            {(!isAChildGuest && enabledFields?.adultEnabledFields?.[INPUTS_FORM?.[0].key].enabled) ||
                            (isAChildGuest && enabledFields?.childEnabledFields?.[INPUTS_FORM?.[0].key].enabled) ? (
                                <div className=" w-full">{inputForm(INPUTS_FORM?.[0])}</div>
                            ) : null}
                            {(!isAChildGuest && enabledFields?.adultEnabledFields?.[INPUTS_FORM?.[1].key].enabled) ||
                            (isAChildGuest && enabledFields?.childEnabledFields?.[INPUTS_FORM?.[1].key].enabled) ? (
                                <div className=" w-full">{inputForm(INPUTS_FORM?.[1])}</div>
                            ) : null}
                        </div>
                        {numberOfRooms > 1 ? (
                            <div className=" flex mr-2  w-full ">
                                <div className=" w-full">{inputForm(INPUTS_FORM?.[2])}</div>
                            </div>
                        ) : null}
                        <div className=" flex mr-2 space-x-4 w-full">
                            {(!isAChildGuest && enabledFields?.adultEnabledFields?.[INPUTS_FORM?.[3].key].enabled) ||
                            (isAChildGuest && enabledFields?.childEnabledFields?.[INPUTS_FORM?.[3].key].enabled) ? (
                                <div className=" w-full ">{inputForm(INPUTS_FORM?.[3])}</div>
                            ) : null}
                            {(!isAChildGuest && enabledFields?.adultEnabledFields?.[INPUTS_FORM?.[4].key].enabled) ||
                            (isAChildGuest && enabledFields?.childEnabledFields?.[INPUTS_FORM?.[4].key].enabled) ? (
                                // <div className=" w-1/2">
                                <div className=" w-full ">{inputForm(INPUTS_FORM?.[4])}</div>
                            ) : // </div>
                            null}
                        </div>
                        <div className=" flex mr-2  space-x-4 w-full">{inputForm(INPUTS_FORM?.[5])}</div>
                        <div className=" flex mr-2  space-x-4 w-full">
                            <div className=" w-full">{inputForm(INPUTS_FORM?.[6])}</div>
                        </div>
                        <div className=" flex mr-2  space-x-4 w-full">{inputForm(INPUTS_FORM?.[7])}</div>
                        <div className=" flex ">{inputForm(INPUTS_FORM?.[8])}</div>
                        <div className=" flex mr-2  space-x-4 w-full">{inputForm(INPUTS_FORM?.[9])}</div>
                        <div className=" flex mr-2  space-x-4 w-full">{inputForm(INPUTS_FORM?.[10])}</div>
                    </section>
                    <SaveButton
                        disabled={saveDisabled}
                        onClick={() => {
                            setLoading(true);

                            if (onModificationAvailable) {
                                queryUpdatePendingChanges({
                                    hotelRef: project?.ref,
                                    code: code,
                                    pendingChangesSend: true,
                                });
                            }

                            if (id === "new") {
                                queryCreate(
                                    parseValidFieldsToMutationVars(
                                        filterValidFieldsWithValue(dataFields, isAChildGuest, enabledFields)
                                    )
                                );
                            } else {
                                queryUpdate(
                                    parseValidFieldsToMutationVars(
                                        filterValidFieldsWithValue(dataFields, isAChildGuest, enabledFields)
                                    )
                                );
                            }
                        }}
                    />
                </section>
            ) : (
                <NationalitiesSearch
                    setNationalitiesSearch={setNationalitiesSearch}
                    onChange={(value) => {
                        const valueId = value?.id;

                        setDataFields({
                            ...dataFields,
                            ["nationality"]: { ...dataFields?.["nationality"], value: valueId },
                        });
                    }}
                />
            )}
            <UsePrecheckinSettingsStayBookings setData={setData} setLoading={setLoading} />
        </>
    );
};

export default FormGuestPrecheckin;
