import gql from "graphql-tag";

export const GET_ROOMS = {
    query: gql`
        query getRooms {
            getRooms {
                results {
                    projectRef
                    number
                    code
                    guestID
                    salesToken
                }
            }
        }
    `,
    defaultVars: {},
};
