import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { useLazyQuery, useMutation as useLazyMutation } from "@apollo/client";
import { getApiClient, evalQueryCache, CLIENT_ZAFIRO_MOBILE, CLIENT_VENDURE_ADMIN } from "Apollo";
import handleGraphqlError from "./handleGraphqlError";
import { getToken } from "actions/config";
import { getToken as getUserToken } from "../actions/user";
import { getRoom } from "../modules/rooms/store";

const DEFAULT_CACHE_POLICY = "cache-and-network";
const DEFAULT_CACHE_TIME = 180000; // 3 minutes

export const useQuery = (graphqlQuery, options) => {
    const { i18n } = useTranslation();
    const i18nLang = (i18n.language || "").match(/^[^-]+/)[0];
    const dispatch = useDispatch();
    const appToken = useSelector(getToken);
    const userToken = useSelector(getUserToken);
    const roomSalesToken = useSelector(getRoom)?.salesToken;
    const [data, setData] = useState(null);
    const [error, setError] = useState(null);

    const languageCode = options?.languageCode || i18nLang;
    const maxAge = options?.maxAge || DEFAULT_CACHE_TIME;
    const fetchPolicy = options?.fetchPolicy || DEFAULT_CACHE_POLICY;
    const displayName = options?.displayName || null;
    const pollInterval = options?.pollInterval || null;
    const token =
        options?.customToken || roomSalesToken || (graphqlQuery?.client === CLIENT_VENDURE_ADMIN ? userToken || appToken : null);
    const customClientID = graphqlQuery?.client || (token ? CLIENT_ZAFIRO_MOBILE : null);
    const vendureToken = options?.shopToken || null;
    const customClient = customClientID ? getApiClient(customClientID, { token, vendureToken, languageCode }) : null;

    const [policy, onPolicySuccess] =
        maxAge > 0
            ? evalQueryCache(graphqlQuery, { ...options, token: userToken || appToken }, maxAge, fetchPolicy)
            : [fetchPolicy, null];

    const [query, { called, loading, data: queryData, error: queryError, networkStatus, refetch, variables }] =
        useLazyQuery(graphqlQuery?.query, {
            fetchPolicy: policy,
            onCompleted: () => {
                if (onPolicySuccess) {
                    onPolicySuccess();
                }
            },
            variables: graphqlQuery?.defaultVars,
            errorPolicy: "all",
            context: { token, vendureToken },
            ...(pollInterval ? { pollInterval } : null),
            ...(displayName ? { displayName } : null),
            ...(customClient ? { client: customClient } : null),
        });

    useEffect(() => {
        setData(queryData);
    }, [queryData]);

    useEffect(() => {
        setError(queryError);
    }, [queryError]);

    useEffect(() => {
        if (error) {
            handleGraphqlError(error, dispatch, null, userToken, variables);
        }
    }, [error]);

    return {
        query: (params) => {
            return query
                ? query({
                      variables: {
                          ...graphqlQuery?.defaultVars,
                          ...params,
                      },
                  })
                : null;
        },
        called,
        loading,
        data,
        error,
        refetch: (params) => {
            // HACK estos states se utilizan para forzar la actualizacion de data/error (que debería ser automatica, pero en algunas queries no está pasando, ejemplo: reload del listado de pedidos)
            return refetch(params)
                .then((r) => {
                    setData(r?.data);
                })
                .catch((e) => {
                    setError(e);
                });
        },
        variables,
        networkStatus,
    };
};

export const useMutation = (graphqlQuery, options) => {
    const appToken = useSelector(getToken);
    const userToken = useSelector(getUserToken);
    const roomSalesToken = useSelector(getRoom)?.salesToken;

    const customClientID = graphqlQuery?.client || (token ? CLIENT_ZAFIRO_MOBILE : null);
    const token = options?.customToken || roomSalesToken || (customClientID === CLIENT_VENDURE_ADMIN ? userToken || appToken : null);
    const vendureToken = options?.shopToken || null;
    const customClient = customClientID ? getApiClient(customClientID, { token, vendureToken }) : null;

    const [query, { called, loading, data, error, networkStatus, reset }] = useLazyMutation(graphqlQuery?.query, {
        variables: graphqlQuery?.defaultVars,
        errorPolicy: "all",
        ...(customClient ? { client: customClient } : null),
    });
    return {
        query: (params) => {
            return query
                ? query({
                      variables: {
                          ...graphqlQuery?.defaultVars,
                          ...params,
                      },
                  })
                : null;
        },
        called,
        loading,
        data,
        error,
        reset,
        networkStatus,
    };
};
