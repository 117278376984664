import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { cloneDeep } from "lodash";

import { useQuery, useMutation } from "./utils";
import {
    SHIPPING_METHOD_PICKUP,
    DELIVERY_SCHEDULE,
    VENDURE_ASSETS_LOCATION,
    tr,
    isColorString,
    encodeExtras,
    parseState,
} from "utils/shop";
import {
    GET_SHOPS,
    GET_COLLECTIONS,
    GET_PRODUCT,
    GET_PRODUCT_EXTRAS,
    ADD_ITEM,
    SET_SHIPPING_METHOD,
    ORDER_NOW,
    ADD_PAYMENT_METHOD,
    ORDER_INFO,
    ORDERS,
    ADD_ORDER_COMMENTS,
    CANCEL_ORDER,
} from "./queries/shop";

// Queries

const defaultCache = {
    maxAge: 600000, // 10 minutes
    fetchPolicy: "cache-first",
};

export const useShops = ({ projectRef, shopToken, lang, search, state = false }) => {
    const query = useQuery(GET_SHOPS, { ...defaultCache });

    const shops = query?.data?.getShops
        ?.filter((s) => !shopToken || s.token === shopToken)
        .filter((s) => !search || String(s?.name).toLowerCase().includes(String(search).toLowerCase()))
        .filter((s) => {
            if (state) {
                return s.customFields.isActive;
            } else {
                return s;
            }
        })
        .map((s) => {
            const shop = cloneDeep(s);
            if (shop) {
                shop.deliverySchedule = shop.customFields?.deliverySchedule
                    ? JSON.parse(shop.customFields.deliverySchedule)
                    : null;

                shop.allowScheduledDelivery = shop.customFields?.allowScheduledDelivery;

                // Distingo entre color y url de imagen
                shop.coverColor = null;
                shop.coverURL = shop.customFields?.coverURL;
                if (isColorString(shop.coverURL)) {
                    shop.coverColor = shop.coverURL;
                    shop.coverURL = null;
                } else if (shop.coverURL) {
                    shop.coverURL = VENDURE_ASSETS_LOCATION + shop.coverURL;
                }

                shop.maxProductsPerOrder = shop.customFields?.isMaxProductPerOrderActive
                    ? shop.customFields?.maxProductsPerOrder
                    : 0;
                shop.delayedDeliveryThreshold = shop.customFields?.delayedDeliveryThreshold;

                shop.autoAcceptOrders = shop.customFields?.autoAcceptOrders;
                shop.isOrderCancellationAllowed =
                    !shop.autoAcceptOrders && shop.customFields?.isOrderCancellationAllowed;

                const shippingConfig = JSON.parse(shop.customFields?.shippingConfig || "{}");
                const pickupLocation = shop.customFields?.pickupLocation?.match(/^\[/)
                    ? JSON.parse(shop.customFields?.pickupLocation || "[]")
                    : [{ languageCode: "en", name: shop.customFields?.pickupLocation }];
                shop.shipping = {
                    config: shippingConfig,
                    location: tr(pickupLocation, lang)?.name || "",
                };

                delete shop.customFields;
            }
            return shop;
        });

    return {
        ...query,
        query: (params) => query.query({ projectRef, ...params }),
        data: shopToken && shops?.length > 0 ? shops[0] : shops,
    };
};

export const useShopCategories = ({ shopToken, parentCategoryId, categoryID, search, searchProducts, level }) => {
    const { i18n } = useTranslation();
    const lang = (i18n.language || "").match(/^[^-]+/)[0];

    const query = useQuery(GET_COLLECTIONS, { shopToken, ...defaultCache });

    const countBC = (c) => c?.breadcrumbs?.length || 0;

    const categories = query?.data?.collections?.items
        ?.filter((c) => categoryID === undefined || c?.id === categoryID)
        .filter((c) => parentCategoryId === undefined || c?.parent?.id === parentCategoryId)
        .filter((c) => (level > 0 ? countBC(c) === level + 1 : true))
        .map((c) => {
            const category = cloneDeep(c);
            if (category) {
                // Muestro el nombre segun el idioma actual
                category.name = tr(category.translations, lang)?.name;
                delete category.translations;

                // Distingo entre color y url de imagen
                category.coverColor = null;
                category.coverURL = category.customFields?.image_url;
                if (isColorString(category.coverURL)) {
                    category.coverColor = category.coverURL;
                    category.coverURL = null;
                } else if (category.coverURL) {
                    category.coverURL = VENDURE_ASSETS_LOCATION + category.coverURL;
                }
                delete category.customFields?.image_url;

                // Normalizo los productos
                category.containsProducts = category.customFields?.can_have_children === false;
                if (category.containsProducts) {
                    category.products = category.productVariants?.items;
                    category.products.forEach((p) => {
                        // Normalizo ID
                        p.id = `${p.product?.id}.${p.id}`;

                        // Nombre traducido
                        p.name = tr(p.product?.translations, lang)?.name || p.name;

                        // Nombre traducido
                        p.description = tr(p.product?.translations, lang)?.description || p.product?.description;

                        delete p.product.translations;

                        // Normalizo imagenes
                        p.coverURL = p.product?.featuredAsset?.source;
                        if (p.coverURL) {
                            p.coverURL = VENDURE_ASSETS_LOCATION + p.coverURL;
                        }
                        delete p.product?.featuredAsset;

                        // Normalizo tags
                        const allergensEnabled = p?.customFields?.are_allergens_enabled;
                        p.tags = [];
                        p.allergens = [];
                        if (p.product?.facetValues?.length > 0) {
                            p.product?.facetValues.forEach((f) => {
                                if (f) {
                                    const tag = {
                                        id: f.code,
                                        name: tr(f.translations, lang)?.name,
                                    };
                                    if (f.facet?.code?.startsWith("zafiro_allergens_")) {
                                        if (allergensEnabled) {
                                            p.allergens.push(tag);
                                        }
                                    } else if (f.facet?.code?.startsWith("zafiro_labels_")) {
                                        p.tags.push(tag);
                                    }
                                }
                            });
                        }
                        delete p.product?.facetValues;

                        p.maxUnitsPerOrder = parseInt(
                            p?.customFields?.max_units_per_order_enabled ? p?.customFields?.max_units_per_order : 0
                        );
                        delete p.customFields;
                    });
                }

                if (searchProducts && category.products?.length > 0) {
                    category.products = category.products.filter(
                        (p) =>
                            String(p.name).toLowerCase().includes(String(searchProducts).toLowerCase()) ||
                            String(p.description).toLowerCase().includes(String(searchProducts).toLowerCase())
                    );
                }
                delete category.customFields?.can_have_children;
                delete category.productVariants;
            }
            return category;
        })
        .filter((c) => c !== null)
        .filter((c) => !c.containsProducts || c.products?.length > 0)
        .filter((c) => !search || String(c?.name).toLowerCase().includes(String(search).toLowerCase()));
    return {
        ...query,
        data:
            categoryID && categories?.length > 0
                ? {
                      categories: categories[0],
                      pickupLocations: query?.data?.activeChannel?.pickupLocations,
                      geolocationTranslations: query?.data?.activeChannel?.geolocationTranslations,
                  }
                : {
                      categories: categories,
                      pickupLocations: query?.data?.activeChannel?.pickupLocations,
                      geolocationTranslations: query?.data?.activeChannel?.geolocationTranslations,
                  },
    };
};

export const useShopProduct = ({ shopToken, productID }) => {
    const { i18n } = useTranslation();
    const lang = (i18n.language || "").match(/^[^-]+/)[0];

    const [vendureProductID, vendureVariantID] = productID?.split(".") || [];

    const query = useQuery(GET_PRODUCT, { shopToken, ...defaultCache });

    const p = cloneDeep(query?.data?.product);

    if (p) {
        // Normalizo ID
        p.id = productID;

        p.name = tr(p.translations, lang)?.name;
        p.description = tr(p.translations, lang)?.description || p.description;

        p.variants.forEach((v) => {
            if (v.id === vendureVariantID) {
                if (!p.name) {
                    p.name = v.name;
                }
                p.price = v.price;
                p.priceWithTax = v.priceWithTax;
                p.currencyCode = v.currencyCode;
                p.taxRateApplied = v.taxRateApplied;
                p.customFields = v.customFields;
            }
        });
        delete p.variants;

        // Normalizo imagenes
        p.coverURL = p.featuredAsset?.source;
        if (p.coverURL) {
            p.coverURL = VENDURE_ASSETS_LOCATION + p.coverURL;
        }
        delete p.featuredAsset;
        p.images = p.assets
            ?.map((a) => (a?.source ? VENDURE_ASSETS_LOCATION + a.source : null))
            .filter((a) => a !== null);
        delete p.assets;
        if (p.coverURL && p.images?.length === 0) {
            // Fix extraño problema
            p.images.push(p.coverURL);
        }

        // Normalizo tags
        const allergensEnabled = p?.customFields?.are_allergens_enabled;
        p.tags = [];
        p.allergens = [];
        if (p.facetValues?.length > 0) {
            p.facetValues.forEach((f) => {
                if (f) {
                    const tag = {
                        id: f.code,
                        name: tr(f.translations, lang)?.name,
                    };
                    if (f.facet?.code?.startsWith("zafiro_allergens_")) {
                        if (allergensEnabled) {
                            p.allergens.push(tag);
                        }
                    } else if (f.facet?.code?.startsWith("zafiro_labels_")) {
                        p.tags.push(tag);
                    }
                }
            });
        }
        delete p.facetValues;
        p.maxUnitsPerOrder = parseInt(
            p?.customFields?.max_units_per_order_enabled ? p?.customFields?.max_units_per_order : 0
        );
        delete p.customFields;
    }

    return {
        ...query,
        query: () => {
            return query.query({ id: vendureProductID });
        },
        data: p,
    };
};

export const useShopExtras = ({ shopToken, productID }) => {
    const { i18n } = useTranslation();
    const lang = (i18n.language || "").match(/^[^-]+/)[0];

    const [vendureProductID] = productID?.split(".") || [];

    const query = useQuery(GET_PRODUCT_EXTRAS, { shopToken, ...defaultCache });

    const extras = query?.data?.extras
        .filter((e) => e?.available)
        .sort((a, b) => (a?.order || 0) - (b?.order || 0))
        .map((e) => {
            return {
                id: e?.id,
                name: tr(e.translations, lang)?.name,
                type: e?.type,
                taxCategory: e?.taxCategory,
                price: e?.options?.priceWithoutTax,
                priceWithTax: e?.options?.priceWithTax,
                min: e?.options?.min,
                max: e?.options?.max,
                choices: e?.options?.choices
                    ?.filter((c) => c?.available)
                    .sort((a, b) => (a?.order || 0) - (b?.order || 0))
                    .map((c) => {
                        return {
                            id: c?.id,
                            name: tr(c?.translations, lang)?.name,
                            priceWithTax: c?.priceWithTax,
                            price: c?.priceWithoutTax,
                            taxCategory: c?.taxCategory,
                        };
                    }),
            };
        });

    return {
        ...query,
        query: () => {
            return query.query({ productId: vendureProductID });
        },
        data: extras,
    };
};

export const useOrderInfo = ({ shopToken, id }) => {
    const query = useQuery(ORDER_INFO, { shopToken });

    const orderInfo = (query?.data?.orderInfo || [null])[0];
    const order = query?.data?.order;
    const shippingMethod = order?.shippingLines?.[0]?.shippingMethod?.code;

    const info =
        order && orderInfo
            ? {
                  state: parseState(order.state, { shippingMethod }),
                  id: order.id,
                  updatedAt: order.updatedAt,
                  createdAt: order.createdAt,
                  orderPlacedAt: order.orderPlacedAt,
                  code: order.code,
                  currencyCode: order.currencyCode,
                  subTotal: order.subTotal,
                  subTotalWithTax: order.subTotalWithTax,
                  shipping: order.shipping,
                  shippingWithTax: order.shippingWithTax,
                  total: order.total,
                  comments: order.history?.items
                      ?.map((h) => h?.data?.note)
                      .filter((h) => h)
                      .join("\n"),
                  totalWithTax: order.totalWithTax,
                  scheduleStart: order.customFields?.scheduleStart,
                  scheduleEnd: order.customFields?.scheduleEnd,
                  deliveryLocation: order.customFields?.deliveryLocation,
                  shippingMethod,
                  products: order.lines?.map((l) => {
                      const item = orderInfo?.customFields?.items?.filter((i) => i.id === l?.productVariant?.id)?.[0];
                      return {
                          name: item?.name || l?.productVariant?.name,
                          quantity: l.quantity,
                          extras: item?.extras,
                          price: l.unitPrice / l.quantity,
                          priceWithTax: l.unitPrice + l.lineTax / l.quantity,
                      };
                  }),
              }
            : null;

    return {
        ...query,
        query: (params) => query.query({ id, ...params }),
        error: query?.error,
        data: info,
    };
};

export const useOrders = ({ shopToken, guestId, roomNumber }) => {
    const query = useQuery(ORDERS, { shopToken });

    const orders = query?.data?.orders?.items;

    const data = orders
        ? orders
              .map((order) => {
                  const shippingMethod = order?.shippingLines?.[0]?.shippingMethod?.code;
                  return order
                      ? {
                            state: parseState(order.state, { shippingMethod }),
                            id: order.id,
                            updatedAt: order.updatedAt,
                            createdAt: order.createdAt,
                            orderPlacedAt: order.orderPlacedAt,
                            code: order.code,
                            currencyCode: order.currencyCode,
                            shipping: order.shipping,
                            shippingWithTax: order.shippingWithTax,
                            total: order.total,
                            totalWithTax: order.totalWithTax,
                            scheduleStart: order.customFields?.scheduleStart,
                            scheduleEnd: order.customFields?.scheduleEnd,
                            deliveryLocation: order.customFields?.deliveryLocation,
                            shippingMethod,
                            totalQuantity: order.totalQuantity,
                            shopToken: order.channels?.[0]?.token,
                        }
                      : null;
              })
              .filter((o) => o !== null)
        : null;

    return {
        ...query,
        query: (params) => {
            return query.query({ guestId, roomNumber, ...params });
        },
        data,
    };
};

// Mutations

export const useShopNewOrder = ({ shopToken, lang }) => {
    const [newOrder, setNewOrder] = useState(null);
    const [awaitQuery, setAwait] = useState(false);

    const addItem = useAddItem({ shopToken });
    const setShippingMethod = useSetShippingMethod({ shopToken });
    const orderNow = useOrderNow({ shopToken, lang });
    const addPaymentMethod = useAddPaymentMethod({ shopToken });
    const addNoteToOrder = useAddNoteToOrder({ shopToken });

    const mutationLoading =
        orderNow?.loading || addItem?.loading || setShippingMethod?.loading || addPaymentMethod?.loading;
    const error = orderNow?.error || addItem?.error || setShippingMethod?.error || addPaymentMethod?.error;
    const loading = !error && (newOrder != null || mutationLoading);

    useEffect(() => {
        if (newOrder && addItem?.called && !addItem?.loading && !addItem?.error) {
            setAwait(false);
        }
    }, [addItem?.called, addItem?.loading, addItem?.error]);

    useEffect(() => {
        if (newOrder && setShippingMethod?.called && !setShippingMethod?.loading && !setShippingMethod?.error) {
            setAwait(false);
        }
    }, [setShippingMethod?.called, setShippingMethod?.loading, setShippingMethod?.error]);

    useEffect(() => {
        if (newOrder && orderNow?.called && !orderNow?.loading && !orderNow?.error) {
            setAwait(false);
        }
    }, [orderNow?.called, orderNow?.loading, orderNow?.error]);

    useEffect(() => {
        if (newOrder && addPaymentMethod?.called && !addPaymentMethod?.loading && !addPaymentMethod?.error) {
            setAwait(false);
        }
    }, [addPaymentMethod?.called, addPaymentMethod?.loading, addPaymentMethod?.error]);

    useEffect(() => {
        if (newOrder && addNoteToOrder?.called && !addNoteToOrder?.loading && !addNoteToOrder?.error) {
            setAwait(false);
        }
    }, [addNoteToOrder?.called, addNoteToOrder?.loading, addNoteToOrder?.error]);

    useEffect(() => {
        if (newOrder) {
            if (error) {
                setNewOrder(null);
            } else if (!awaitQuery) {
                if (newOrder.items?.length > 0) {
                    addItem.query(newOrder.items[0]);
                    setAwait(true);
                    const newItems = newOrder?.items?.slice(1);
                    setNewOrder({
                        ...newOrder,
                        items: newItems?.length > 0 ? newItems : null,
                    });
                } else if (newOrder.shipping) {
                    setShippingMethod.query(newOrder.shipping);
                    setAwait(true);
                    setNewOrder({
                        ...newOrder,
                        shipping: null,
                    });
                } else if (newOrder.order) {
                    orderNow.query(newOrder.order);
                    setAwait(true);
                    setNewOrder({
                        ...newOrder,
                        order: null,
                    });
                } else if (newOrder.payment) {
                    addPaymentMethod.query(newOrder.payment);
                    setAwait(true);
                    setNewOrder({
                        ...newOrder,
                        payment: null,
                    });
                } else if (newOrder.comments) {
                    addNoteToOrder.query({
                        id: orderNow?.data?.id,
                        ...newOrder.comments,
                    });
                    setAwait(true);
                    setNewOrder({
                        ...newOrder,
                        comments: null,
                    });
                } else {
                    setAwait(false);
                    setNewOrder(null);
                }
            }
        }
    }, [newOrder, awaitQuery, error]);

    return {
        called: newOrder !== null || orderNow?.called,
        loading,
        error,
        data: orderNow?.data
            ? {
                  ...orderNow?.data,
              }
            : null,
        query: (params) => {
            if (!newOrder) {
                const guestId = params?.guestId;
                const roomNumber = params?.roomNumber;
                const cart = params?.cart;
                const shippingMethodID = parseInt(cart?.shippingMethodID);
                const deliveryLocation =
                    shippingMethodID == SHIPPING_METHOD_PICKUP.id ? cart.shippingLocationID : cart?.shippingLocation;
                const deliveryType = cart?.deliveryType;
                const deliveryTime = cart?.deliveryTime?.split(",");
                const products = cart?.products;

                let scheduleStart, scheduleEnd;
                if (deliveryType === DELIVERY_SCHEDULE) {
                    if (deliveryTime?.length > 1) {
                        scheduleStart = parseInt(deliveryTime[0]);
                        scheduleEnd = parseInt(deliveryTime[1]);
                    }
                }
                if (!scheduleStart) {
                    scheduleStart = new Date().getTime();
                }
                scheduleStart = scheduleStart ? String(scheduleStart) : null;
                scheduleEnd = scheduleEnd ? String(scheduleEnd) : null;

                const items = [];
                products?.forEach((p) => {
                    const itemProps = {
                        variantId: p?.productID?.split(".")[1],
                        quantity: p?.quantity,
                        guestId,
                        roomNumber,
                        deliveryLocation,
                        scheduleStart,
                        scheduleEnd,
                        extras: encodeExtras(p?.selections),
                    };
                    items.push(itemProps);
                });

                setNewOrder({
                    items,
                    shipping: {
                        methodId: shippingMethodID,
                        guestId,
                        roomNumber,
                    },
                    order: {
                        guestId,
                        roomNumber,
                        deliveryLocation,
                        scheduleStart,
                        scheduleEnd,
                    },
                    payment: {
                        methodCode: "charge-to-room",
                        guestId,
                        roomNumber,
                    },
                    ...(cart?.comments
                        ? {
                              comments: {
                                  note: cart?.comments,
                                  isPublic: true,
                              },
                          }
                        : null),
                });
            }
        },
    };
};

const useOrderNow = ({ shopToken }) => {
    const query = useMutation(ORDER_NOW, { shopToken, fetchPolicy: "network-only" });
    const order = query?.data?.transitionOrderToState;

    return {
        ...query,
        error: query?.error || (order?.errorCode ? new Error(order?.message) : null),
        data: order?.errorCode ? null : order,
    };
};

const useSetShippingMethod = ({ shopToken }) => {
    const query = useMutation(SET_SHIPPING_METHOD, { shopToken, fetchPolicy: "network-only" });
    const data = query?.data?.setOrderShippingMethod;
    return {
        ...query,
        error: query?.error || (data?.errorCode ? new Error(data?.message) : null),
        data,
    };
};

const useAddItem = ({ shopToken }) => {
    const query = useMutation(ADD_ITEM, { shopToken, fetchPolicy: "network-only" });
    const data = query?.data?.addItemToOrder;
    return {
        ...query,
        error: query?.error || (data?.errorCode ? new Error(data?.message) : null),
        data,
    };
};

const useAddPaymentMethod = ({ shopToken }) => {
    const query = useMutation(ADD_PAYMENT_METHOD, { shopToken, fetchPolicy: "network-only" });
    const data = query?.data?.addPaymentToOrder;
    return {
        ...query,
        error: query?.error || (data?.errorCode ? new Error(data?.message) : null),
        data,
    };
};

const useAddNoteToOrder = ({ shopToken }) => {
    const query = useMutation(ADD_ORDER_COMMENTS, { shopToken, fetchPolicy: "network-only" });
    const data = query?.data?.addNoteToOrder;
    return {
        ...query,
        error: query?.error || (data?.errorCode ? new Error(data?.message) : null),
        data,
    };
};

export const useCancelOrder = ({ shopToken }) => {
    const query = useMutation(CANCEL_ORDER, { shopToken, fetchPolicy: "network-only" });
    return (id) =>
        new Promise((resolve, reject) => {
            query
                ?.query({ id: id })
                .then((r) => {
                    if (r?.data?.cancelOrder?.errorCode) {
                        console.error("Error cancelling order", r?.data?.cancelOrder);
                        reject(r?.data?.cancelOrder);
                    } else {
                        resolve(r?.data?.cancelOrder);
                    }
                })
                .catch((e) => {
                    console.error("Error cancelling order", e);
                    reject(e);
                });
        });
};
