import React, { useContext, useEffect, useState } from "react";
import _ from "lodash";
import { useTranslation } from "react-i18next";
import { size as renderSize } from "zrender/sizes";
import { getDeviceWidth, nl2br } from "./Design/DesignUtils";
import { ShopContext } from "../contexts/shop";
import {
    printPrice,
    isOpen,
    nextTimeOpen,
    displayTime,
    displayTimeAndDate,
    SHIPPING_METHOD_PICKUP,
    DELIVERY_SOON,
    DELIVERY_SCHEDULE,
    ORDER_STATE_PENDING,
    ORDER_STATE_DELIVERED,
    ORDER_STATE_CANCELLED,
    ORDER_STATE_ACCEPTED,
    ORDER_STATE_IN_PROGRESS,
    SHIPPING_METHOD_GEOLOCATION,
    getName,
} from "../utils/shop";
import { useOrderInfo } from "../graphql/shop";
import Loading from "../components/Loading";
import ErrorMessage from "../components/ErrorMessage";
import ProductsList from "../components/ProductsList";
import { markdown } from "utils/others";
import Tag from "./Tag";
import { MapComponent } from "./Map";
import { Marker } from "./Marker";
import { Wrapper, Status } from "@googlemaps/react-wrapper";
import useLandingSession from "hooks/useLandingSession";
import { useShopCategories } from "../graphql/shop";

const ShopOrder = React.forwardRef(function ShopOrderComponent(
    { lang, orderID, orderInfo, isNew, isMessage, style, topBarRef, children },
    ref
) {
    const { shopToken, cleanCart, hideCart } = useContext(ShopContext);
    const { t, size } = useShopRender({ lang });

    const styles = {
        box: {
            color: "#323232",
            backgroundColor: isMessage ? "transparent" : "#FBFBFB",
            padding: size(1),
            marginBottom: isMessage ? 0 : size(0.75),
        },
        boxTitle: {
            fontWeight: 600,
            fontSize: "1.1em",
            borderBottom: "1px solid rgba(50,50,50,.1)",
            marginBottom: size(1),
            paddingLeft: size(0.5),
            paddingRight: size(0.5),
        },
        boxContent: {
            paddingTop: isMessage ? 0 : size(0.5),
            paddingLeft: size(0.5),
            paddingRight: size(0.5),
            paddingBottom: size(0.5),
        },
    };

    const {
        query: loadOrder,
        called: loadOrderCalled,
        loading: loadingOrder,
        error: loadOrderError,
        data: orderData,
        refetch: reloadOrder,
    } = useOrderInfo({ shopToken, id: orderID });

    useEffect(() => {
        topBarRef?.current?.setTitle(t("order completed"));
        topBarRef?.current?.setBack(hideCart);
    }, [topBarRef]);

    useEffect(() => {
        if (cleanCart) {
            cleanCart();
        }
        if (!loadOrderCalled && !orderInfo) {
            loadOrder();
        }
    }, []);

    // Exponer el método doSomething a través de la referencia
    React.useImperativeHandle(ref, () => ({
        reload: () => {
            reloadOrder();
        },
    }));

    const loading = loadingOrder;
    const error = loadOrderError;

    if (loading) {
        return <Loading />;
    }

    if (error) {
        return <ErrorMessage type="connection" error={error} />;
    }

    if (loadOrderCalled && !orderData) {
        return <div className="bg-neutral-50 text-error text-center p-5">Order #{orderID} not found</div>;
    }

    if (!loadOrderCalled && !orderInfo) {
        return null;
    }

    const ChildsProps = {
        styles,
        lang,
        isNew,
        isMessage,
        orderInfo: orderInfo || orderData,
    };

    return (
        <div
            style={{
                fontSize: size(1.4),
                paddingBottom: size(0.7),
                marginTop: size(1.4),
                marginBottom: size(1.4),
                ...style,
            }}
        >
            {children ? (
                React.Children.map(children, (child) => {
                    return React.cloneElement(child, ChildsProps);
                })
            ) : (
                <Layout {...ChildsProps} />
            )}
        </div>
    );
});

const useShopRender = ({ lang }) => {
    const { theme } = useContext(ShopContext);
    const { t: tr } = useTranslation();
    const t = (label, data) => {
        return tr(label, { lng: lang, ...(data || {}) });
    };

    const size = (v) => renderSize(v, "mobile", getDeviceWidth());

    return {
        t,
        size,
        theme,
    };
};

const Layout = (props) => {
    const [map, setMap] = useState(null);
    const { styles, lang, isNew, isMessage, orderInfo } = props;
    const { t } = useShopRender({ lang });
    const { landing } = useLandingSession();
    const { deliverySchedule, shopToken } = useContext(ShopContext);
    const isClosed = !isOpen(deliverySchedule);
    const { query: loadCategories, data: categories } = useShopCategories({ shopToken, level: 1 });
    useEffect(() => {
        loadCategories();
    }, []);
    const nextDeliveryTime = isClosed ? nextTimeOpen(deliverySchedule, { lang }) : null;

    const comments = orderInfo?.comments;
    const shippingMethod = orderInfo?.shippingMethod;

    const deliveryLocation =
        shippingMethod === SHIPPING_METHOD_PICKUP.code
            ? getName(orderInfo?.deliveryLocation, categories?.pickupLocations, lang)
            : orderInfo?.deliveryLocation;
    const deliveryType = orderInfo?.scheduleEnd ? DELIVERY_SCHEDULE : DELIVERY_SOON;

    const deliveryTime = (() => {
        const scheduleStart = orderInfo?.scheduleStart;
        const scheduleEnd = orderInfo?.scheduleEnd;
        if (scheduleStart && scheduleEnd) {
            const start = new Date(scheduleStart);
            const end = new Date(scheduleEnd);
            return (
                displayTime(start.getHours(), start.getMinutes(), { lang }) +
                " - " +
                displayTime(end.getHours(), end.getMinutes(), { lang })
            );
        }
        return null;
    })();

    const render = (status) => {
        switch (status) {
            case Status.LOADING:
                return <p>Loading</p>;
            case Status.FAILURE:
                return <p>Fallo</p>;
            case Status.SUCCESS:
                return (
                    <MapComponent current={JSON.parse(deliveryLocation)} setMap={setMap} map={map}>
                        <Marker position={JSON.parse(deliveryLocation)} map={map} title={t("my-position")}></Marker>
                    </MapComponent>
                );
        }
    };

    return (
        <>
            {isNew ? (
                <div style={styles?.box}>
                    <div style={styles?.boxContent}>
                        <div>{markdown(t("order has been completed"))}</div>
                        {shippingMethod && deliveryLocation ? (
                            <div
                                style={{ paddingTop: ".5em" }}
                                dangerouslySetInnerHTML={{
                                    __html: t(
                                        shippingMethod === SHIPPING_METHOD_PICKUP.code
                                            ? "you will pick up your order in x soon"
                                            : deliveryType === DELIVERY_SOON
                                            ? "you will receive your order in x soon"
                                            : "you will receive your order in x",
                                        {
                                            location: `<strong>${_.escape(
                                                shippingMethod === SHIPPING_METHOD_GEOLOCATION.code
                                                    ? t("your-confirmed-location")
                                                    : deliveryLocation
                                            )}</strong>`,
                                        }
                                    ),
                                }}
                            ></div>
                        ) : null}

                        {deliveryType === DELIVERY_SOON && isClosed ? (
                            <div style={{ opacity: 0.6, paddingTop: ".5em" }}>
                                {"* "}
                                {markdown(
                                    t("next delivery from x", {
                                        from: nextDeliveryTime,
                                    })
                                )}
                            </div>
                        ) : null}

                        {deliveryType === DELIVERY_SCHEDULE ? (
                            <div style={{ paddingTop: ".5em" }}>
                                {t("delivery time") + ": "}
                                <strong>{deliveryTime}</strong>
                            </div>
                        ) : null}

                        {shippingMethod === SHIPPING_METHOD_GEOLOCATION.code ? (
                            <Wrapper apiKey={landing?.googleApiKey} render={render}></Wrapper>
                        ) : null}
                    </div>
                </div>
            ) : null}
            {isMessage ? null : <OrderProducts {...props} />}
            {!isNew ? <OrderDelivery {...props} deliveryLocation={deliveryLocation} /> : null}
            {isMessage ? <OrderProducts {...props} /> : null}
            {orderInfo.state !== ORDER_STATE_CANCELLED && <OrderSummary {...props} />}
            {comments?.length > 0 ? (
                <div style={styles?.box}>
                    <div style={styles?.boxTitle}>{t("comments")}</div>
                    <div style={styles?.boxContent}>{nl2br(comments)}</div>
                </div>
            ) : null}
        </>
    );
};

export const OrderProducts = ({ styles, isMessage, lang, orderInfo }) => {
    const { t, size, theme } = useShopRender({ lang });
    const { pricesIncludeTax } = useContext(ShopContext);
    const currencyCode = orderInfo?.currencyCode;
    const items = orderInfo?.products || [];
    const productsCount = items.reduce((sum, p) => sum + p.quantity, 0);
    return (
        <div style={styles?.box}>
            <div style={styles?.boxTitle}>{isMessage ? t("order") : t("your order")}</div>
            <div style={styles?.boxContent}>
                {isMessage ? null : (
                    <div>
                        {t("x products in your order", {
                            count: orderInfo.state != ORDER_STATE_CANCELLED ? productsCount : "",
                        })}
                    </div>
                )}
                {items?.length > 0 ? (
                    <ProductsList
                        style={
                            isMessage
                                ? {
                                      borderBottom: "1px solid rgba(50,50,50,.1)",
                                  }
                                : null
                        }
                        items={items?.map((product, index) => {
                            const extras = [];
                            product.extras?.forEach((extra) => {
                                if (extra.options?.length > 0) {
                                    extra.options.forEach((option) => {
                                        extras.push({
                                            name: option.name,
                                            quantity: option.quantity || 1,
                                        });
                                    });
                                } else {
                                    extras.push({
                                        name: extra.name,
                                        quantity: extra.quantity || 1,
                                    });
                                }
                            });
                            return {
                                order: {
                                    quantity: product.quantity,
                                    extras: extras,
                                },
                                orderState: orderInfo.state,
                                index,
                                keyID: `shop_order__product_${product.name}_${index}`,
                                name: product.name,
                                isMessage,
                                price: pricesIncludeTax ? product.priceWithTax : product.price,
                                currency: currencyCode,
                                maxUnitsPerOrder: product.maxUnitsPerOrder,
                                editable: false,
                                lang,
                                style: {
                                    global: {
                                        background: "transparent",
                                        paddingLeft: 0,
                                        paddingRight: 0,
                                        paddingTop: isMessage ? 0 : size(1.5),
                                        paddingBottom: isMessage ? 0 : size(1.5),
                                        borderBottom: isMessage ? null : "1px solid rgba(50,50,50,.1)",
                                    },
                                    tag: {
                                        background: theme?.style?.details?.colors?.bgColor,
                                        color: theme?.style?.details?.colors?.fgColor,
                                    },
                                },
                            };
                        })}
                    />
                ) : null}
            </div>
        </div>
    );
};

export const OrderDelivery = ({ styles, lang, isMessage, orderInfo: order, deliveryLocation }) => {
    const [map, setMap] = useState();
    const { t, size, theme } = useShopRender({ lang });
    const { landing } = useLandingSession();
    const { autoAcceptOrders } = useContext(ShopContext);

    const details = theme?.style?.details?.colors;

    const state =
        autoAcceptOrders && (order.state === ORDER_STATE_PENDING || order.state === ORDER_STATE_IN_PROGRESS)
            ? ORDER_STATE_ACCEPTED
            : order.state;

    const deliveryTime = (() => {
        if (state === ORDER_STATE_DELIVERED) {
            return displayTimeAndDate(new Date(order.orderPlacedAt), { lang });
        } else {
            const scheduleStart = order?.scheduleStart;
            const scheduleEnd = order?.scheduleEnd;
            if (scheduleStart && scheduleEnd) {
                const start = new Date(scheduleStart);
                const end = new Date(scheduleEnd);
                return (
                    displayTime(start.getHours(), start.getMinutes(), { lang }) +
                    " - " +
                    displayTimeAndDate(end, { lang })
                );
            }
            return t("as soon as possible");
        }
    })();

    const render = (status) => {
        switch (status) {
            case Status.LOADING:
                return <p>Loading</p>;
            case Status.FAILURE:
                return <p>Fallo</p>;
            case Status.SUCCESS:
                return (
                    <MapComponent current={JSON.parse(order.deliveryLocation)} setMap={setMap} map={map}>
                        <Marker
                            position={JSON.parse(order.deliveryLocation)}
                            map={map}
                            title={t("my-position")}
                        ></Marker>
                    </MapComponent>
                );
        }
    };

    return (
        <div style={styles?.box}>
            <div
                style={{
                    ...styles?.boxTitle,
                    display: "flex",
                    justifyContent: "space-between",
                }}
            >
                <div>{t("delivery")}</div>
                {!isMessage && state != ORDER_STATE_PENDING ? (
                    <div>
                        <Tag
                            className="rounded-lg"
                            style={{
                                background: details?.bgColor,
                                color: details?.fgColor,
                                marginLeft: size(1.5),
                                marginRight: 0,
                            }}
                            title={displayTimeAndDate(new Date(order.updatedAt), { lang })}
                        >
                            {t(`order_state_${state}`)}
                        </Tag>
                    </div>
                ) : null}
            </div>
            <div style={styles?.boxContent}>
                {isMessage ? (
                    <>
                        <div>
                            {order.shippingMethod === SHIPPING_METHOD_PICKUP.code ? t("pick up") : t("room service")}
                            {order.shippingMethod === SHIPPING_METHOD_PICKUP.code ? (
                                <> ({order.deliveryLocation})</>
                            ) : null}
                        </div>
                        <div>{deliveryTime}</div>
                    </>
                ) : (
                    <>
                        <div
                            style={{
                                display: "flex",
                                justifyContent: "space-between",
                                marginBottom: size(1),
                            }}
                        >
                            <div style={{ fontWeight: 600 }}>{t("schedule")}</div>
                            <div>{deliveryTime}</div>
                        </div>
                        <div
                            style={{
                                display: "flex",
                                justifyContent: "space-between",
                                marginBottom: size(1),
                            }}
                        >
                            <div style={{ fontWeight: 600 }}>{t("delivery method")}</div>
                            <div className="text-right">
                                {order.shippingMethod === SHIPPING_METHOD_PICKUP.code
                                    ? t("pick up")
                                    : order.shippingMethod === SHIPPING_METHOD_GEOLOCATION.code
                                    ? t("your-confirmed-location")
                                    : t("room service")}
                                {order.shippingMethod === SHIPPING_METHOD_PICKUP.code ? (
                                    <>
                                        <br />
                                        <span style={{ color: "rgba(50, 50, 50, 0.6)" }}>{deliveryLocation}</span>
                                    </>
                                ) : null}
                            </div>
                        </div>
                        {order.shippingMethod === SHIPPING_METHOD_GEOLOCATION.code ? (
                            <Wrapper apiKey={landing?.googleApiKey} render={render}></Wrapper>
                        ) : null}
                    </>
                )}
            </div>
        </div>
    );
};

export const OrderSummary = ({ styles, isMessage, lang, orderInfo }) => {
    const { t, size } = useShopRender({ lang });
    const { pricesIncludeTax } = useContext(ShopContext);

    const shippingPrice = pricesIncludeTax ? orderInfo?.shippingWithTax : orderInfo?.shipping;
    const totalPrice = pricesIncludeTax ? orderInfo?.totalWithTax : orderInfo?.total;
    const currencyCode = orderInfo?.currencyCode;
    const productsPrice = totalPrice - shippingPrice;

    return (
        <div style={styles?.box}>
            {isMessage ? null : <div style={styles?.boxTitle}>{t("summary")}</div>}
            <div style={styles?.boxContent}>
                <div
                    style={{
                        display: "flex",
                        justifyContent: "space-between",
                        marginBottom: isMessage ? size(0.5) : size(1),
                    }}
                >
                    <div>{isMessage ? t("subtotal") : t("products")}</div>
                    <div style={{ fontWeight: 600 }}>{printPrice(productsPrice, currencyCode, lang)}</div>
                </div>
                <div
                    style={{
                        display: "flex",
                        justifyContent: "space-between",
                        marginBottom: isMessage ? size(0.5) : size(1),
                    }}
                >
                    <div>{t("delivery")}</div>
                    <div style={{ fontWeight: 600 }}>{printPrice(shippingPrice, currencyCode, lang)}</div>
                </div>
                <div
                    style={{
                        display: "flex",
                        justifyContent: "space-between",
                        borderTop: "1px solid rgba(50,50,50,.1)",
                        marginTop: size(0.5),
                        marginBottom: size(0.5),
                        paddingTop: size(1),
                        fontWeight: 600,
                    }}
                >
                    <div>{t("total")}</div>
                    <div>{printPrice(totalPrice, currencyCode, lang)}</div>
                </div>
                <div
                    style={{
                        textAlign: isMessage ? "left" : "right",
                        fontSize: isMessage ? ".9em" : "1em",
                    }}
                >
                    {pricesIncludeTax ? t("tax included") : t("tax not included")}
                </div>
            </div>
        </div>
    );
};

export default ShopOrder;
