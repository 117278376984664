import jwtDecode from "jwt-decode";
import { isPortal } from "../utils/config";

export const UPDATE_APP_TOKEN = "UPDATE_APP_TOKEN";
export const SET_SIGNUP_FORM_VALUES = "SET_SIGNUP_FORM_VALUES";
export const SET_IS_EMBEDDED = "SET_IS_EMBEDDED";

export function setToken(value) {
    return (dispatch) => {
        dispatch({
            type: UPDATE_APP_TOKEN,
            payload: value,
        });
    };
}

export function getToken(state) {
    return state?.cache?.auth?.token || null;
}

export function getIsEmbedded(state) {
    // Embedded in iOS/Android app
    return state?.cache?.isEmbedded || null;
}

export function setSignupFormValues(value) {
    return (dispatch) => {
        dispatch({
            type: SET_SIGNUP_FORM_VALUES,
            payload: value,
        });
    };
}

export function setIsEmbedded(value) {
    // Embedded in iOS/Android app
    // When GET url param projectRef
    return setDispatch(SET_IS_EMBEDDED, value);
}

export function invalidateToken() {
    return setToken(null);
}

export function getWifiUrl() {
    if (window.appConfig && window.appConfig.wifiURL) {
        return window.appConfig.wifiURL;
    }
    return "/wifi-srv";
}

export function getWifiToken() {
    if (isPortal()) {
        if (window.appConfig && window.appConfig.wifiToken) {
            return window.appConfig.wifiToken;
        }
        return localStorage ? localStorage.getItem("portalWiFi") : null;
    }
    return null;
}

export function mikrotikSimulator() {
    return (
        (window.appConfig && window.appConfig.mikrotikSimulator) ||
        (localStorage && localStorage.getItem("mikrotikSimulator") === "true")
    );
}

export function portalSimulator() {
    return (
        (window.appConfig && window.appConfig.portalSimulator) ||
        (localStorage && localStorage.getItem("portalSimulator") === "true")
    );
}

export function castSimulator() {
    return (
        (window.appConfig && window.appConfig.castSimulator) ||
        (localStorage && localStorage.getItem("castSimulator") === "true")
    );
}

export function getApiUrl() {
    if (window.appConfig && window.appConfig.apiURL) {
        return window.appConfig.apiURL;
    }
    return "/graphql";
}

export function getChainRef() {
    if (window.appConfig && window.appConfig.chainRef) {
        return window.appConfig.chainRef;
    }
    const portalWiFi = getWifiToken();
    if (portalWiFi) {
        try {
            let config = jwtDecode(portalWiFi);
            if (config) {
                return config.ChainRef;
            }
        } catch (err) {
            console.error(err.message || err);
        }
    }
    return "";
}

export function getAppRef() {
    return (window.appConfig ? window.appConfig.host : "") || "";
}

export function getSiteName() {
    return window.appConfig.name || "Unknown site name";
}

export function getDasUrl() {
    if (window.appConfig && window.appConfig.dasURL) {
        return window.appConfig.dasURL;
    }
    return "/das";
}

export function getIconAssetRef() {
    if (window.appConfig && window.appConfig.iconAssetRef) {
        return window.appConfig.iconAssetRef;
    }
    return "";
}

export function getLogoRef(isLigth = false) {
    if (window.appConfig && window.appConfig.logos && window.appConfig.logos.length > 0) {
        var logo;
        if (isLigth) {
            logo = window.appConfig.logos.find((logo) => logo.version === "others_light_icon");
        } else {
            logo = window.appConfig.logos.find((logo) => logo.version === "others_dark_icon");
        }
        return logo && logo.imageRef ? logo.imageRef : "";
    }
    return "";
}

export function getAssetsUrl() {
    if (window.appConfig && window.appConfig.assetsURL) {
        return window.appConfig.assetsURL;
    }
    return "/assets";
}

export function isReady() {
    return window.appConfig ? window.appConfig.ready : false;
}

export function getMobileUrl(projectRef) {
    if (projectRef) {
        let url = "/ext";
        if (window.appConfig && window.appConfig.extURL) {
            url = window.appConfig.extURL;
        }
        return url + "/" + projectRef + "/mobile.php";
    }
    return null;
}

export function getZonesUrl() {
    if (window.appConfig && window.appConfig.zonesURL) {
        return window.appConfig.zonesURL;
    }
    return "/zones";
}

export function getVendureUrl() {
    if (window.appConfig && window.appConfig.vendureURL) {
        return window.appConfig.vendureURL;
    }
    return "/vendure";
}

export function getVendureShopAPIUrl() {
    if (window.appConfig && window.appConfig.vendureURL) {
        return window.appConfig.vendureURL + "/shop-api";
    }
    return "/vendure/shop-api";
}

export function getVendureAdminAPIUrl() {
    if (window.appConfig && window.appConfig.vendureURL) {
        return window.appConfig.vendureURL + "/admin-api";
    }
    return "/vendure/admin-api";
}

export function getReservationsUrl() {
    if (window.appConfig && window.appConfig.reservationsURL) {
        return window.appConfig.reservationsURL;
    }
    return "/zones/reservations";
}

function setDispatch(type, value) {
    return (dispatch) => {
        dispatch({
            type: type,
            payload: value,
        });
    };
}
