import React, { useEffect, useState, useLayoutEffect } from "react";
import Header from "components/Header";
import { useTranslation } from "react-i18next";
import StepProgress from "components/StepProgress";
// import InformationStay from "./InformationStay";
import InformationStayCard from "./InformationStayCard";
import PrecheckinTab from "./PrecheckinTab";
import GuestArrows from "./GuestArrows";
import { UsePrecheckinSettingsStayBookings } from "graphql/stays";
import SendInformation from "./SendInformation";
import {
    PRECHECKIN_STATUS,
    resizeArrayGuestsOrder,
    updateIdGuestArrayGuestsOrder,
    generateArrayGuestsOrder,
} from "./utils";
import { useHistory, useParams } from "react-router-dom/cjs/react-router-dom.min";
import Loading from "components/Loading";
import { useDispatch, useSelector } from "react-redux";
import { setStaysBookingsGuestsOrder } from "actions/stays";
import { getStaysBookingsGuestOrders } from "actions/stays";

const PreCheckin = () => {
    const { t } = useTranslation();
    const { step } = useParams();
    const dispatch = useDispatch();
    const history = useHistory();

    const staysBookingGuestsOrder = useSelector(getStaysBookingsGuestOrders);

    const [currentStep, setCurrentStep] = useState(0);

    const [data, setData] = useState(null);
    const [dataStayBooking, setDataStayBooking] = useState(null);
    const [dataStayFields, setDataStayFields] = useState(null);
    const [stepsEnabled, setStepsEnabled] = useState({ 0: true, 1: false, 2: false });
    const [stepsVisited, setStepsVisited] = useState([]);
    const [loading, setLoading] = useState(true);
    const [termsAccepted, setTermsAccepted] = useState(false);
    const [refetch, setRefetch] = useState(false);

    const bookingInPendingModification =
        dataStayBooking?.state === PRECHECKIN_STATUS.PENDING_MODIFICATION.value ? true : false;

    const bookingPendingInCompletion =
        dataStayBooking?.state === PRECHECKIN_STATUS.PENDING_COMPLETION.value ? true : false;

    const bookingCompleted = dataStayBooking?.state === PRECHECKIN_STATUS.COMPLETED.value ? true : false;

    useEffect(() => {
        if (bookingCompleted) {
            history.push("/stays");
        }
    }, [bookingCompleted]);

    useEffect(() => {
        setDataStayBooking(data?.getStayBookings);
        setDataStayFields({
            preCheckInSettingsFields: data?.preCheckInSettingsFields || {},
            preCheckInSettingsFieldsChildren: data?.preCheckInSettingsFieldsChildren || {},
            preCheckInSettings: data?.preCheckInSettings || {},
        });

        if (data?.getStayBookings) {
            const numberOfGuests = data?.getStayBookings?.numberOfGuests;

            const currentArrGuestsOrder = staysBookingGuestsOrder?.[data?.getStayBookings?.code]
                ? staysBookingGuestsOrder[data?.getStayBookings?.code]
                : null;

            let arrGuestsOrder = currentArrGuestsOrder
                ? resizeArrayGuestsOrder(currentArrGuestsOrder, numberOfGuests)
                : generateArrayGuestsOrder(numberOfGuests);

            arrGuestsOrder = updateIdGuestArrayGuestsOrder(arrGuestsOrder, data?.getStayBookings?.guests);

            if (data?.getStayBookings?.numberOfGuests) {
                dispatch(
                    setStaysBookingsGuestsOrder({
                        code: data?.getStayBookings?.code,
                        arrGuestsOrder: arrGuestsOrder,
                        numberOfGuests: numberOfGuests,
                    })
                );
            }
        }
    }, [data]);

    useEffect(() => {
        if (dataStayBooking) {
            if (dataStayBooking.termsAccepted) {
                setTermsAccepted(true);
            } else {
                setTermsAccepted(false);
            }
            if (
                (!dataStayBooking?.termsAccepted && currentStep === 1) ||
                (!dataStayBooking?.termsAccepted && currentStep === 2)
            ) {
                setCurrentStep(0);
            }
        }
    }, [dataStayBooking]);

    useLayoutEffect(() => {
        if (step === "2") {
            setCurrentStep(1);
            setStepsEnabled({ 0: true, 1: true, 2: false });
        }
    }, [step]);

    useEffect(() => {
        if (currentStep === 1 || currentStep === 2) {
            setRefetch(true);
        }
    }, [currentStep]);

    useEffect(() => {
        if (dataStayBooking?.state && dataStayBooking?.state !== PRECHECKIN_STATUS.PENDING_COMPLETION.value) {
            setStepsVisited([0, 1, 2]);
            setStepsEnabled({ 0: true, 1: true, 2: true });
        } else {
            setStepsVisited((prevSteps) => {
                if (!prevSteps.includes(currentStep)) {
                    return [...prevSteps, currentStep];
                }
                return prevSteps;
            });
        }
    }, [currentStep, dataStayBooking?.state]);

    return (
        <section className="basic has-tabs has-top">
            {loading ? (
                <div className=" w-full h-full absolute top-0 left-0 bg-white z-30 ">
                    <Loading />
                </div>
            ) : null}
            <Header
                backAction={() => {
                    if (currentStep > 0) {
                        setCurrentStep(currentStep - 1);
                    } else {
                        history.push("/stays");
                    }
                }}
                title={t("pre-check-in")}
                alertIcon={bookingInPendingModification}
            />
            <section style={{ paddingLeft: "0rem", paddingRight: "0rem" }} className="  relative">
                {!bookingPendingInCompletion ? (
                    <div
                        style={{ backgroundColor: "#323232", top: "3.5rem" }}
                        className=" w-full h-16 flex px-4 items-center justify-center text-white absolute "
                    >
                        <i className=" icon mr-2 text-2xl  icon-info"></i>
                        <span className=" ">{t("remember-to-resend-information-if-any-changes")}</span>
                    </div>
                ) : null}
                <div
                    style={{ paddingTop: !bookingPendingInCompletion ? "5.5rem" : null }}
                    className=" w-ful py-5 -mt-6 border border-neutral-40 px-4 mb-4 bg-white"
                >
                    <StepProgress
                        currentStep={currentStep}
                        stepsVisited={stepsVisited}
                        stepsEnabled={stepsEnabled}
                        setCurrentStep={setCurrentStep}
                        steps={[{ name: "booking" }, { name: "guests" }, { name: "send" }]}
                    />
                </div>
                <div className=" bg-neutral-contrast-10 -mt-4" style={{ height: "1px", width: "100%" }}></div>
                <div
                    className={`${currentStep === 0 ? " mt-6" : ""}`}
                    style={{ paddingLeft: "1rem", paddingRight: "1rem" }}
                >
                    {currentStep === 0 ? (
                        <InformationStayCard
                            dataStayBooking={dataStayBooking}
                            inInformationStay={false}
                            setTermsAccepted={setTermsAccepted}
                            termsAccepted={termsAccepted}
                        />
                    ) : null}
                    {currentStep === 1 ? (
                        <GuestArrows
                            dataStayFields={dataStayFields}
                            setStepsEnabled={setStepsEnabled}
                            dataStayBooking={dataStayBooking}
                        />
                    ) : null}
                    {currentStep === 2 ? <SendInformation dataStayBooking={dataStayBooking} /> : null}
                </div>
            </section>
            <PrecheckinTab
                currentStep={currentStep}
                setCurrentStep={setCurrentStep}
                stepsEnabled={stepsEnabled}
                setStepsEnabled={setStepsEnabled}
                setLoading={setLoading}
                termsAccepted={termsAccepted}
                dataStayBooking={dataStayBooking}
            />
            <UsePrecheckinSettingsStayBookings
                setData={setData}
                setLoading={setLoading}
                refetch={refetch}
                setRefetch={setRefetch}
            />
        </section>
    );
};

export default PreCheckin;
