import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import { useForm } from "react-hook-form";

const AddRoom = (params) => {
  const { t } = useTranslation();
  const [loading, setLoading] = useState(false);
  // To fix Chrome autocomplete
  const [autoCompleteDodge, setAutoCompleteDodged] = useState(false);
  const [textPwd, setTextPwd] = useState(false);
  //
  const [passVisible, setPassVisible] = useState(false);
  const togglePassVisibility = () => {
    setPassVisible(!passVisible);
  };

  const {
    register,
    handleSubmit,
    watch,
    errors,
    triggerValidation,
  } = useForm();

  const submitForm = (data, e) => {
    setLoading(true);
    params.onSuccess(data, e);
  };

  return (
    <>
      <div className="alert-title border-none">
        <span>{t("enter password")}</span>
      </div>

      <form
        onSubmit={handleSubmit(submitForm)}
        className={"px-5" + (loading ? " opacity-50" : "")}
      >
        <div className="my-3">
          <div
            className={
              "float-left text-sm pl-1 pb-1 " + (!watch("room") && "invisible")
            }
          >
            {t("room")}
          </div>
          <input
            ref={register({
              required: t("required input"),
            })}
            type="number"
            name="room"
            disabled={loading}
            autoComplete="off"
            placeholder={t("room")}
            onChange={() => triggerValidation("room")}
            onBlur={() => triggerValidation("room")}
            onFocus={() => setAutoCompleteDodged(true)}
            className={errors.room ? "error" : ""}
          />
          {errors.room && (
            <div className="text-error float-right text-sm">
              {errors.room.message}
            </div>
          )}
        </div>

        <div className="mt-3 mb-5">
          <div
            className={
              "float-left text-sm pl-17 pb-1 " + (!watch("code") && "invisible")
            }
          >
            {t("password")}
          </div>
          <div className="relative">
            <input
              ref={register({
                required: t("required input"),
              })}
              type={
                passVisible || !autoCompleteDodge || textPwd
                  ? "text"
                  : "password"
              }
              name="code"
              disabled={loading}
              autoComplete="off"
              placeholder={t("password")}
              onChange={() => triggerValidation("code")}
              onBlur={() => {
                triggerValidation("code");
                setTextPwd(false);
              }}
              onFocus={() => {
                setAutoCompleteDodged(true);
                setTextPwd(false);
              }}
              className={errors.code ? "error" : ""}
            />
            <i
              className={
                "absolute right-0 m-3 text-neutral-contrast-50 text-xl cursor-pointer icon icon-eye-" +
                (passVisible ? "on" : "off")
              }
              onClick={() => togglePassVisibility()}
            ></i>
            {errors.code && (
              <div className="text-error float-right text-sm">
                {errors.code.message}
              </div>
            )}
          </div>

          <div className="flex justify-between mt-10">
            <button
              type="button"
              className="flex-1 mr-1 btn text-lg"
              onClick={params.onCancel}
            >
              {t("cancel")}
            </button>
            <button
              type="submit"
              disabled={loading}
              className="flex-1 ml-1 btn btn-accent text-lg"
            >
              {t("add")}
            </button>
          </div>
        </div>
      </form>
    </>
  );
};

export default AddRoom;
