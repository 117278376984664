import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useHistory, useParams } from "react-router-dom/cjs/react-router-dom.min";
import { useSelector } from "react-redux";
import { getStaysBookingsGuestOrders } from "actions/stays";
import { getRecordByIndexFromCurrentStaysBookingGuestsOrder } from "./utils";

const GuestArrows = ({ dataStayBooking, dataStayFields, setStepsEnabled }) => {
    const [guests, setGuests] = useState([]);

    const arrows = Array.from({ length: dataStayBooking?.numberOfGuests }, (_, index) => index);
    // const guests = dataStayBooking?.guests;
    const { t } = useTranslation();
    const { code } = useParams();
    const history = useHistory();
    const staysBookingGuestsOrder = useSelector(getStaysBookingsGuestOrders);
    const currentStaysBookingGuestsOrder = staysBookingGuestsOrder[code] ? staysBookingGuestsOrder[code] : null;

    useEffect(() => {
        if (dataStayBooking?.guests?.length > 0) {
            const newGuests = dataStayBooking?.guests.map((guest) => {
                return {
                    ...guest,
                    showCheck: areRequiredFieldsFilled(
                        dataStayFields?.preCheckInSettingsFields,
                        guest,
                        dataStayFields?.preCheckInSettingsFieldsChildren,
                        dataStayFields?.preCheckInSettings
                    ),
                };
            });
            setGuests(newGuests);
        }
    }, [dataStayBooking?.guests]);

    useEffect(() => {
        if (guests.length > 0 && arrows) {
            const guestsWithDataFilled = [];
            guests.forEach((guest) => {
                if (guest.showCheck === true) {
                    guestsWithDataFilled.push(true);
                }
            });

            if (guestsWithDataFilled.length === arrows.length) {
                setStepsEnabled((prevSteps) => {
                    return { ...prevSteps, 2: true };
                });
            } else {
                setStepsEnabled((prevSteps) => {
                    return { ...prevSteps, 2: false };
                });
            }
        }
    }, [guests]);

    return (
        <div>
            {arrows.map((index) => {
                const idGuest = getRecordByIndexFromCurrentStaysBookingGuestsOrder(
                    currentStaysBookingGuestsOrder,
                    index
                )
                    ? getRecordByIndexFromCurrentStaysBookingGuestsOrder(currentStaysBookingGuestsOrder, index).id
                    : null;
                const dataGuest = guests.find((guest) => guest.id === Number(idGuest));

                return (
                    <button
                        onClick={() => {
                            if (idGuest) {
                                history.push(`/stays/details/${code}/precheckin/${idGuest}/${index}`);
                            } else {
                                history.push(`/stays/details/${code}/precheckin/new/${index}`);
                            }
                        }}
                        id={`guest-arrow-${index}`}
                        className=" flex items-center justify-between py-4 px-2 w-full border-b border-neutral-contrast-10"
                    >
                        <div className=" flex items-center">
                            <div className=" flex font-bold text-neutral-contrast">
                                <span className=" block">{t("guest")}</span>
                                <span className=" block ml-1">{index + 1}</span>
                            </div>
                            {dataGuest?.showCheck ? (
                                <div className=" flex ml-4 items-center justify-center">
                                    <i className=" icon icon-checkmark text-accent"></i>
                                </div>
                            ) : null}
                        </div>

                        <div className=" flex items-center">
                            {dataGuest?.stayBookingRoomIndex ? (
                                <div className=" opacity-1 mr-4 text-neutral-contrast-40">{`${t("room")} ${Number(
                                    dataGuest?.stayBookingRoomIndex
                                )}`}</div>
                            ) : null}
                            <i className=" icon text-neutral-contrast icon-right"></i>
                        </div>
                    </button>
                );
            })}
        </div>
    );
};
function areRequiredFieldsFilled(config, data, configChild, settings) {
    if (!data) {
        return false;
    }

    const fieldMappings = {
        nationality: "countryRef",
        documentExpeditionDate: "expeditionDate",
        phone: "phoneNumber",
        birthdate: "birthDate",
    };

    let currentConfig = config;
    if (data?.birthDate && settings?.requestDataFromChildren) {
        const birthDate = new Date(data.birthDate);
        const today = new Date();
        let age = today.getFullYear() - birthDate.getFullYear();
        const m = today.getMonth() - birthDate.getMonth();
        if (m < 0 || (m === 0 && today.getDate() < birthDate.getDate())) {
            age--;
        }

        if (age <= settings?.maximumAgeOfChildren) {
            currentConfig = configChild;
        }
    }

    for (let key in currentConfig) {
        const field = currentConfig[key];
        if (field?.enabled && field?.required) {
            let fieldName = fieldMappings[key] || key.charAt(0).toLowerCase() + key.slice(1);

            let dataValue;
            if (fieldName === "expeditionDate") {
                dataValue = data.documentExtraInformation && data.documentExtraInformation[fieldName];
            } else {
                dataValue = data[fieldName];
            }
            if (dataValue === null || dataValue === undefined || dataValue?.trim() === "") {
                return false;
            }
        }
    }
    return true;
}

export default GuestArrows;
