import { useEffect, useState } from "react";
import { cloneDeep } from "lodash";
import { isToday } from "../i18n";
import { useQuery } from "./utils";
import { GET_MESSAGES_MULTIPLE } from "./queries/messages";

const defaultCache = {
    maxAge: 60000, // 1 minute
    fetchPolicy: "cache-first",
};

export const useMessages = ({ rooms, pollInterval }) => {
    const roomsParams = ((data) => {
        const params = [];
        if (data && typeof data === "object") {
            Object.entries(data).forEach(([projectRef, projectRooms]) => {
                if (projectRooms && typeof projectRooms === "object") {
                    Object.entries(projectRooms).forEach(([roomNumber, roomData]) => {
                        params.push({
                            projectRef,
                            roomNumber,
                            password: roomData?.code,
                        });
                    });
                }
            });
        }
        return params;
    })(rooms);

    // If there are no rooms, query is not needed
    const enabled = roomsParams.length > 0;

    const query = useQuery(GET_MESSAGES_MULTIPLE(roomsParams), {
        ...defaultCache,
        ...(pollInterval ? { maxAge: pollInterval - 1000 } : null),
        pollInterval: enabled ? pollInterval : null,
    });
    const [data, setData] = useState(null);

    useEffect(() => {
        const messages = query?.data
            ? Object.values(query.data)
                  ?.map((value) => (value?.results ? [...value.results] : []))
                  .reduce((a, b) => [...a, ...b], [])
                  .map((m) => {
                      const msg = cloneDeep(m);
                      msg.today = false;
                      msg.hour = null;
                      if (msg?.sentTime) {
                          const date = new Date(msg.sentTime);
                          msg.today = isToday(date);
                          msg.hour = date.toLocaleTimeString([], { hour: "2-digit", minute: "2-digit" });
                      }
                      msg.information = msg.information ? JSON.parse(msg.information) : null;
                      msg.plainBody = msg.body.replaceAll(/\{\{\.(\w+)\}\}/g, "...");
                      return msg;
                  })
            : null;

        setData(messages);
    }, [query?.data]);

    return {
        ...query,
        query: (params) => (enabled && query ? query.query(params) : null),
        data,
    };
};
