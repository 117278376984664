import React, { useRef, useState, Suspense } from "react";
import { Redirect } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import { useTranslation } from "react-i18next";
import Header from "../components/Header";
import Tabs from "../components/Tabs";
import swal from "@sweetalert/with-react";
import { toast } from "react-toastify";
import { getSelectedHotel, setSelectedRoom } from "../actions/hotel";
import useReaderScan from "../hooks/useReaderScan";
import history from "../routes/history";
import { getMobileUrl } from "../actions/config";

const isTesting = process.env.NODE_ENV === "test";
const QrReader = !isTesting ? React.lazy(() => import("react-qr-scanner")) : null;

const ScanQR = () => {
    const qr = useRef();
    const { t } = useTranslation();
    const dispatch = useDispatch();

    const selectedHotel = useSelector(getSelectedHotel);
    const selectedHotelRef = selectedHotel?.ref;

    const [errors, setErrors] = useState(0);
    const [redirectTo, setRedirectTo] = useState("");

    const {
        onReaderScan,
        onReaderLoad,
        onReaderError,
        onReaderChooseDevice,
        legacyMode,
        devices,
        stopScan,
        currentDevice,
        setCurrentDevice,
        toggleDevice,
    } = useReaderScan({
        getURL: () => {
            return selectedHotel && selectedHotel.mobilePath ? getMobileUrl(selectedHotelRef) : null;
        },
        onSuccess: async (room) => {
            if (room) {
                dispatch(setSelectedRoom(room));
                setRedirectTo("/site");
            } else {
                setTimeout(stopScan, 3000);
            }
        },
        onError: (error) => {
            setErrors(errors + 1);
            switch (error) {
                case "NOT_FOUND_DATA":
                    toast(t("invalid qr code"));
                    setTimeout(stopScan, 3000);
                    break;
                default:
                    if (errors >= 3) {
                        swal({
                            title: t("invalid qr code title"),
                            text: t("invalid qr code info"),
                            buttons: {
                                cancel: t("alert cancel"),
                                confirm: t("rescan qr"),
                            },
                        }).then((confirm) => {
                            if (confirm) {
                                stopScan();
                            } else {
                                history.goBack();
                            }
                            swal.close();
                        });
                    } else {
                        stopScan();
                    }
            }
        },
    });

    const updateCam = (id) => {
        if (currentDevice && qr && qr.current) {
            qr.current.clearComponent();
            qr.current.initiate({
                ref: qr,
                delay: 1000,
                facingMode: "rear",
                resolution: 1080,
                showViewFinder: false,
                chooseDeviceId: () => id,
                onLoad: onReaderLoad,
                onError: onReaderError,
                onScan: onReaderScan,
                legacyMode: legacyMode,
                maxImageSize: 1080,
            });
        }
    };

    if (!selectedHotelRef) {
        return <Redirect push to="/site/list" />;
    }

    const openImageDialog = () => {
        if (qr.current && legacyMode) {
            qr.current.openImageDialog();
        }
    };

    if (redirectTo) {
        return <Redirect push to={redirectTo} />;
    }

    const toggleCam = () => {
        updateCam(toggleDevice());
    };

    const showCam = (id) => {
        setCurrentDevice(id);
        updateCam(id);
    };

    return (
        <section className="basic has-tabs has-top">
            <Header title={t("scan qr")} />
            <section>
                {isTesting ? null : (
                    <Suspense fallback={<div>Loading...</div>}>
                        <QrReader
                            ref={qr}
                            delay={1000}
                            facingMode="rear"
                            resolution={1080}
                            showViewFinder={false}
                            onLoad={onReaderLoad}
                            chooseDeviceId={onReaderChooseDevice}
                            onError={onReaderError}
                            onScan={onReaderScan}
                            legacyMode={legacyMode}
                            maxImageSize={1080}
                            className="qr-view"
                        />
                    </Suspense>
                )}
                {!legacyMode && (
                    <div className="mt-3 flex justify-between">
                        {devices &&
                            devices.length > 2 &&
                            devices.map((device, key) => {
                                const id = device.deviceId;
                                const name = t("camera x", { number: key + 1 });
                                const selectedDevice = currentDevice === id;
                                return (
                                    <label
                                        key={key}
                                        className={
                                            "w-full mx-1 btn btn-thin btn-" +
                                            (selectedDevice ? "neutral-contrast" : "neutral")
                                        }
                                        onClick={() => {
                                            showCam(id);
                                        }}
                                    >
                                        {name}
                                    </label>
                                );
                            })}
                    </div>
                )}
                {!legacyMode && devices && devices.length === 2 && (
                    <div className="p-5 text-center">
                        <i className="icon icon-cam-switch text-4xl cursor-pointer" onClick={toggleCam}></i>
                    </div>
                )}
                {legacyMode && (
                    <div className="p-5 text-center">
                        <i className="icon icon-photo-add text-4xl cursor-pointer" onClick={openImageDialog}></i>
                    </div>
                )}
            </section>
            <Tabs />
        </section>
    );
};

export default ScanQR;
