import React, { useContext, useEffect, useState } from "react";
import _ from "lodash";
import { useTranslation } from "react-i18next";
import { size as renderSize } from "zrender/sizes";
import { getDeviceWidth } from "./Design/DesignUtils";
import { ShopContext } from "../contexts/shop";
import CheckBox from "../components/CheckBox";
import DropDown from "./DropDown";
import { markdown } from "utils/others";
import Footer from "./Footer";
import { Wrapper, Status } from "@googlemaps/react-wrapper";
import { MapComponent } from "./Map";
import { Marker } from "./Marker";

import {
    SHIPPING_METHOD_PICKUP,
    SHIPPING_METHOD_DELIVERY,
    DELIVERY_SCHEDULE,
    DELIVERY_SOON,
    printPrice,
    isOpen,
    nextTimeOpen,
    getScheduleOptions,
    SHIPPING_METHOD_GEOLOCATION,
} from "../utils/shop";

import ProductsList from "../components/ProductsList";
import { useSendTrackEventGeneralMobile } from "graphql/useUser";
import { useParams } from "react-router-dom/cjs/react-router-dom.min";
import useLandingSession from "hooks/useLandingSession";

const ShopCart = ({ lang, topBarRef }) => {
    const { landing } = useLandingSession();
    const {
        delayedDeliveryThreshold,
        currencyCode,
        pricesIncludeTax,
        deliverySchedule,
        allowScheduledDelivery,
        cart,
        cartPriceSum,
        theme,
        setProductID,
        setCartItemID,
        hideCart,
        setComments,
        setShippingMethod,
        setDeliveryType,
        setDeliveryTime,
        orderNow,
        setPickupOption,
        setShippingPrice,
        hideMap,
        setCustomLocation,
        maxProductsPerOrder,
        shippingMethods,
        pickupLocations,
        customLocation,
        shop,
    } = useContext(ShopContext);

    const { t: tr } = useTranslation();
    const [map, setMap] = useState();
    const t = (label, data) => {
        return tr(label, { lng: lang, ...(data || {}) });
    };

    const { id } = useParams();

    const isClosed = !isOpen(deliverySchedule);
    const { sendTrackEventGeneralMobile } = useSendTrackEventGeneralMobile();
    const shopName = shop?.name || "";

    const nextDeliveryTime = isClosed ? nextTimeOpen(deliverySchedule, { lang }) : null;

    const scheduleOptions = getScheduleOptions(deliverySchedule, { delayedDeliveryThreshold, lang });
    scheduleOptions.forEach((option) => {
        const [start] = option.value.split(",");
        const isTomorrow = new Date(parseInt(start)).getDate() !== new Date().getDate();
        if (isTomorrow) {
            option.name += " (" + t("tomorrow") + ")";
        }
    });

    const pickUpOptions = pickupLocations
        .filter((val) => val.available)
        .map((val) => {
            const name =
                val.translations.filter((value) => value.languageCode == lang) ||
                val.translations.filter((value) => value.languageCode == (lang == "es" ? "en" : "es"));
            const price = printPrice(pricesIncludeTax ? val.price.withTax : val.price.withoutTax, currencyCode, lang);
            if (name.length >= 1) {
                return {
                    value: val.id,
                    name: name[0].name,
                    subtext: `(+ ${price}, ${pricesIncludeTax ? t("tax included") : t("tax not included")})`,
                    price: val.price.withoutTax,
                    priceWithTax: val.price.withTax,
                };
            } else {
                return {
                    value: val.id,
                    name: val.translations[0]?.name ?? "",
                    subtext: `+ ${price} (${pricesIncludeTax ? t("tax included") : t("tax not included")})`,
                    price: val.price.withoutTax,
                    priceWithTax: val.price.withTax,
                };
            }
        });

    const [comments, updateComments] = useState(cart?.comments || "");

    const shippingPrice = pricesIncludeTax ? cart?.shippingPriceWithTax : cart?.shippingPrice;

    const shippingMethodSelector = shippingMethods?.length > 1;

    const size = (v) => renderSize(v, "mobile", getDeviceWidth());

    const items = cart?.products || [];
    const cartProductsCount = items?.length || 0;
    const cartQuantitySum = cartProductsCount > 0 ? items.reduce((sum, item) => sum + item.quantity, 0) : 0;
    const disabled = maxProductsPerOrder > 0 && cartQuantitySum > maxProductsPerOrder;

    useEffect(() => {
        topBarRef?.current?.setTitle(t("cart"));
        topBarRef?.current?.setBack(hideCart);
    }, [topBarRef]);

    useEffect(() => {
        if (cartProductsCount === 0) {
            hideCart();
        }
    }, [cartProductsCount]);

    useEffect(() => {
        if (!cart?.shippingMethodID && shippingMethods?.length > 0) {
            setShippingMethod(shippingMethods[0]);
            setShippingPrice({
                price: shippingMethods[0].price,
                priceWithTax: shippingMethods[0].priceWithTax,
            });
        }
        if (!cart?.deliveryType) {
            setDeliveryType(DELIVERY_SOON);
        }
    }, [shippingMethods]);

    useEffect(() => {
        if (cart?.shippingMethod === SHIPPING_METHOD_GEOLOCATION.code) {
            const coords = cart?.shippingLocation?.includes("lat")
                ? JSON.parse(cart?.shippingLocation)
                : { lat: 0, lng: 0 };
            if (coords?.lat && coords?.lng) {
                setCustomLocation(coords);
            } else if (!customLocation.lat && !customLocation.lng) {
                hideMap(true);
                if (cart?.deliveryType === DELIVERY_SCHEDULE) {
                    setDeliveryType(DELIVERY_SOON);
                }
            }
        }
    }, [cart]);

    useEffect(() => {
        if (
            cart?.shippingMethod === SHIPPING_METHOD_GEOLOCATION.code &&
            customLocation.lat != 0 &&
            customLocation.lng != 0 &&
            !cart?.shippingLocation
        ) {
            setPickupOption({ name: JSON.stringify(customLocation), shippingLocationID: undefined });
        }
    }, [customLocation]);

    const footer = theme?.style?.section2?.status?.default?.sections?.general?.colors;
    const footerButton = theme?.style?.section2?.status?.default?.sections?.button;

    const styles = {
        box: {
            color: "#323232",
            backgroundColor: "#FBFBFB",
            padding: size(1),
            marginBottom: size(0.75),
        },
        boxTitle: {
            fontWeight: 600,
            fontSize: "1.1em",
            borderBottom: "1px solid rgba(50,50,50,.1)",
            marginBottom: size(1),
            paddingLeft: size(0.5),
            paddingRight: size(0.5),
        },
        boxContent: {
            padding: size(0.5),
        },
    };

    const render = (status) => {
        switch (status) {
            case Status.LOADING:
                return <p>Loading</p>;
            case Status.FAILURE:
                return <p>Fallo</p>;
            case Status.SUCCESS:
                return (
                    <MapComponent
                        current={customLocation}
                        setMap={setMap}
                        map={map}
                        onClick={() => {
                            hideMap(true);
                        }}
                    >
                        <Marker position={customLocation} map={map} title={t("my-position")}></Marker>
                    </MapComponent>
                );
        }
    };

    return (
        <div
            style={{
                fontSize: size(1.4),
                paddingBottom: size(0.7),
                marginTop: size(1.4),
                marginBottom: size(1.4),
            }}
        >
            <div style={styles.box}>
                <div style={styles.boxTitle}>{t("delivery")}</div>
                <div style={styles.boxContent}>
                    {shippingMethodSelector ? (
                        <>
                            <div>{t("select delivery method")}:</div>
                            {shippingMethods.map((method, index) => {
                                const type = method?.code;
                                let price = pricesIncludeTax ? method?.priceWithTax : method?.price;
                                const next = index + 1 >= shippingMethods.length ? 0 : index + 1;
                                let nextMethod = shippingMethods[next];
                                if (nextMethod.id === SHIPPING_METHOD_PICKUP.id && pickUpOptions.length < 1) {
                                    nextMethod = shippingMethods[next + 1 >= shippingMethods.length ? 0 : next + 1];
                                }
                                let name;
                                switch (type) {
                                    case SHIPPING_METHOD_DELIVERY.code:
                                        name = t("room services");
                                        break;
                                    case SHIPPING_METHOD_PICKUP.code:
                                        if (cart?.shippingLocationID) {
                                            price = pricesIncludeTax
                                                ? pickUpOptions.find((val) => val.value == cart?.shippingLocationID)
                                                      .priceWithTax
                                                : pickUpOptions.find((val) => val.value == cart?.shippingLocationID)
                                                      .price;
                                        }

                                        name = markdown(
                                            pickUpOptions.length == 1
                                                ? t("pick up at x", { location: pickUpOptions[0].name })
                                                : t("pick up"),
                                            true
                                        );

                                        break;
                                    case SHIPPING_METHOD_GEOLOCATION.code:
                                        name = t("my-location");
                                        break;
                                    default:
                                        name = _.escape(type);
                                        break;
                                }

                                return (
                                    <div key={"shipping_method_container_" + type}>
                                        <div
                                            key={"shipping_method_" + type}
                                            style={{
                                                display: "flex",
                                                alignItems: "center",
                                                justifyContent: "space-between",
                                            }}
                                        >
                                            <RadioButton
                                                {...{
                                                    id: "shipping-" + type,
                                                    checked: cart?.shippingMethod === type,
                                                    text: name,
                                                    hide:
                                                        (type === SHIPPING_METHOD_PICKUP.code &&
                                                            pickUpOptions.length < 1) ||
                                                        type === SHIPPING_METHOD_GEOLOCATION.code,
                                                    onChange: (value) => {
                                                        setShippingMethod(value ? method : nextMethod);
                                                        setCustomLocation({ lat: 0, lng: 0 });
                                                        if (
                                                            type === SHIPPING_METHOD_PICKUP.code &&
                                                            pickUpOptions.length > 0
                                                        ) {
                                                            setPickupOption({
                                                                name: pickUpOptions[0].name,
                                                                shippingLocationID: pickUpOptions[0].value,
                                                            });
                                                            setShippingPrice({
                                                                price: pickUpOptions[0].price,
                                                                priceWithTax: pickUpOptions[0].priceWithTax,
                                                            });
                                                        } else {
                                                            setShippingPrice(value ? method : nextMethod);
                                                        }
                                                    },
                                                }}
                                            />
                                            {price > 0 && (
                                                <div style={{ fontSize: ".9em", color: "#76889B" }}>
                                                    {"+ " + printPrice(price, currencyCode, lang)}
                                                </div>
                                            )}
                                        </div>
                                        {type == SHIPPING_METHOD_GEOLOCATION.code && (
                                            <p>{tr("shipping-geolocation-description")}</p>
                                        )}
                                        {cart?.shippingMethod === SHIPPING_METHOD_PICKUP.code &&
                                            type === SHIPPING_METHOD_PICKUP.code &&
                                            pickUpOptions.length > 1 && (
                                                <DropDown
                                                    key={"shipping_method_pickup"}
                                                    style={{
                                                        display: "block",
                                                        marginTop: size(0.75),
                                                        marginBottom: size(0.75),
                                                    }}
                                                    onChange={(value) => {
                                                        const selected = pickUpOptions.find(
                                                            (val) => val.value == value
                                                        );
                                                        setPickupOption({
                                                            name: selected.name,
                                                            shippingLocationID: selected.value,
                                                        });
                                                        setShippingPrice(selected);
                                                    }}
                                                    options={pickUpOptions}
                                                    selected={cart?.shippingLocationID}
                                                />
                                            )}
                                        {cart.shippingMethod === SHIPPING_METHOD_GEOLOCATION.code &&
                                        type === SHIPPING_METHOD_GEOLOCATION.code &&
                                        customLocation.lat &&
                                        customLocation.lng ? (
                                            <Wrapper apiKey={landing?.googleApiKey} render={render}></Wrapper>
                                        ) : null}
                                    </div>
                                );
                            })}
                        </>
                    ) : null}

                    {!shippingMethodSelector &&
                    cart?.shippingMethod === SHIPPING_METHOD_DELIVERY.code &&
                    cart?.shippingLocation
                        ? markdown(t("products will be delivered to x", { location: cart?.shippingLocation }))
                        : null}
                    {!shippingMethodSelector &&
                    cart?.shippingMethod === SHIPPING_METHOD_PICKUP.code &&
                    cart?.shippingLocation
                        ? markdown(
                              t("products will be pick up at x", {
                                  location: cart?.shippingLocation,
                              })
                          )
                        : null}
                    {!shippingMethodSelector && shippingPrice > 0 ? (
                        <div style={{ fontWeight: 600 }}>
                            {t("this service cost a supplement of x", {
                                cost: printPrice(shippingPrice, currencyCode, lang),
                            })}
                        </div>
                    ) : null}

                    <div style={{ fontWeight: 600, marginTop: size(1) }}>{t("delivery time")}:</div>
                    {allowScheduledDelivery ? (
                        <>
                            {RadioButton({
                                id: "delivery-soon",
                                checked: cart?.deliveryType === DELIVERY_SOON,
                                text: t("as soon as possible"),
                                onChange: (value) => {
                                    setDeliveryType(value ? DELIVERY_SOON : DELIVERY_SCHEDULE);
                                },
                            })}

                            {RadioButton({
                                id: "delivery-schedule",
                                checked: cart?.deliveryType === DELIVERY_SCHEDULE,
                                text: t("schedule delivery"),
                                disabled: cart.shippingMethod === SHIPPING_METHOD_GEOLOCATION.code,
                                onChange: (value) => {
                                    setDeliveryType(value ? DELIVERY_SCHEDULE : DELIVERY_SOON);
                                },
                            })}
                        </>
                    ) : null}
                    {!allowScheduledDelivery ? t("order will be received as soon as possible") : null}
                    <div style={{ marginTop: size(1.5) }}>
                        {cart?.deliveryType === DELIVERY_SOON && isClosed ? (
                            <>
                                {"* "}
                                {markdown(
                                    t("next delivery from x", {
                                        from: nextDeliveryTime,
                                    })
                                )}
                            </>
                        ) : null}
                        {cart?.deliveryType === DELIVERY_SCHEDULE ? (
                            <DropDown
                                key={"delivery_schedule_time"}
                                style={{
                                    display: "block",
                                    marginTop: size(0.75),
                                    marginBottom: size(0.75),
                                }}
                                onChange={(value) => {
                                    setDeliveryTime(value);
                                }}
                                options={scheduleOptions}
                                selected={cart?.deliveryTime}
                                placeholder={t("select hour")}
                            />
                        ) : null}
                    </div>
                </div>
            </div>

            <div style={styles.box}>
                <div style={styles.boxTitle}>{t("comments")}</div>
                <div style={styles.boxContent}>
                    <div>{t("description comments order")}</div>
                    <textarea
                        style={{
                            padding: size(1),
                            border: "1px solid rgba(50,50,50,.1)",
                            borderRadius: size(0.5),
                            marginTop: size(0.5),
                        }}
                        placeholder={t("write your comments")}
                        rows="6"
                        id="comments"
                        onChange={(e) => {
                            updateComments(e?.target?.value);
                        }}
                        onBlur={() => {
                            setComments(comments);
                        }}
                        value={comments}
                    ></textarea>
                </div>
            </div>

            <div style={styles.box}>
                <div style={styles.boxTitle}>{t("your order")}</div>
                <div style={styles.boxContent}>
                    <div>{t("x products in your order", { count: cartQuantitySum })}</div>
                    {maxProductsPerOrder > 0 && maxProductsPerOrder <= cartQuantitySum ? (
                        <div style={{ fontSize: ".9em", color: "rgba(50,50,50,.5)" }}>
                            {`* ${t("limit of products per order reached")}`}
                        </div>
                    ) : null}
                    {cartProductsCount > 0 ? (
                        <ProductsList
                            items={items?.map((item, index) => {
                                return {
                                    index,
                                    id: item?.productID,
                                    cartItemID: index + 1,
                                    keyID: `shop_cart_product_${item?.productID}_${index}`,
                                    name: item?.name,
                                    price: item?.price,
                                    currency: currencyCode,
                                    maxUnitsPerOrder: item?.maxUnitsPerOrder,
                                    confirmRemove: true,
                                    style: {
                                        global: {
                                            background: "transparent",
                                            paddingLeft: 0,
                                            paddingRight: 0,
                                            ...(index + 1 < cartProductsCount
                                                ? { borderBottom: "1px solid rgba(50,50,50,.1)" }
                                                : null),
                                        },
                                        tag: {
                                            background: theme?.style?.details?.colors?.bgColor,
                                            color: theme?.style?.details?.colors?.fgColor,
                                        },
                                    },
                                    lang,
                                    onClick: () => {
                                        setProductID(item?.productID);
                                        setCartItemID(index + 1);
                                    },
                                };
                            })}
                        />
                    ) : null}
                </div>
            </div>

            <div style={styles.box}>
                <div style={styles.boxTitle}>{t("summary")}</div>
                <div style={styles.boxContent}>
                    <div
                        style={{
                            display: "flex",
                            justifyContent: "space-between",
                            marginBottom: size(1),
                        }}
                    >
                        <div>{t("products")}</div>
                        <div style={{ fontWeight: 600 }}>{printPrice(cartPriceSum, currencyCode, lang)}</div>
                    </div>
                    <div
                        style={{
                            display: "flex",
                            justifyContent: "space-between",
                            marginBottom: size(1),
                        }}
                    >
                        <div>{t("delivery")}</div>
                        <div style={{ fontWeight: 600 }}>{printPrice(shippingPrice, currencyCode, lang)}</div>
                    </div>
                    <div
                        style={{
                            display: "flex",
                            justifyContent: "space-between",
                            borderTop: "1px solid rgba(50,50,50,.1)",
                            marginTop: size(0.5),
                            marginBottom: size(0.5),
                            paddingTop: size(1),
                            fontWeight: 600,
                        }}
                    >
                        <div>{t("total")}</div>
                        <div>{printPrice(cartPriceSum + shippingPrice, currencyCode, lang)}</div>
                    </div>
                    <div style={{ textAlign: "right" }}>
                        {pricesIncludeTax ? t("tax included") : t("tax not included")}
                    </div>
                </div>
            </div>

            <div style={{ height: size(7) }}></div>
            <Footer
                theme={{
                    fgColor: footer?.fgColor,
                    bgColor: footer?.bgColor,
                }}
            >
                <div
                    style={{
                        textAlign: "center",

                        fontSize: size(1.5),
                        fontWeight: "bold",
                        padding: size(0.75) + " " + size(1.5),
                        color: disabled ? "white" : footerButton?.colors?.fgColor,
                        background: disabled ? "rgba(50,50,50,.5)" : footerButton?.colors?.bgColor,
                        borderWidth: footerButton?.border?.width + "px",
                        borderRadius: footerButton?.border?.radius + "rem",
                        borderColor: disabled ? "transparent" : footerButton?.border?.color,
                        cursor: disabled ? "default" : "pointer",

                        marginTop: size(0.5),
                        marginLeft: size(2.5),
                        marginRight: size(2.5),
                    }}
                    id="order_now"
                    onClick={
                        disabled
                            ? null
                            : () => {
                                  orderNow();
                                  sendTrackEventGeneralMobile(
                                      {
                                          orderShopId: id,
                                          orderShopName: shopName,
                                          orderProducts: simplifiedProductsForTrack(cart.products),
                                      },
                                      "ORDER"
                                  );
                              }
                    }
                >
                    <div>{t("order now")}</div>
                </div>
            </Footer>
        </div>
    );
};

const RadioButton = ({ id, checked, onChange, text, disabled = false, hide = false }) => {
    const size = (v) => renderSize(v, "mobile", getDeviceWidth());
    return (
        <>
            {!hide && (
                <CheckBox
                    id={id}
                    key={text}
                    checked={checked}
                    style={{
                        display: "block",
                        marginTop: size(0.75),
                        marginBottom: size(0.75),
                        marginRight: size(0.5),
                    }}
                    disabled={disabled}
                    onChange={onChange}
                    text={text}
                    radioButton={true}
                />
            )}
        </>
    );
};

export default ShopCart;

const simplifiedProductsForTrack = (products) => {
    return products.map((product) => {
        const firstPartID = product.productID.split(".")[0];
        return {
            id: parseInt(firstPartID, 10),
            name: product.name,
        };
    });
};
