import { gql } from "graphql-tag";

export const GET_STAY_BOOKING_BY_CODE = {
    query: gql`
        query getStayBookings($hotelRef: ID!, $codes: [String]!) {
            getStayBookings(hotelRef: $hotelRef, filter: { codes: $codes }, page: 1, size: 100) {
                info {
                    count
                    page
                    size
                }
                results {
                    id
                    code
                    arrivalDate
                    departureDate
                    numberOfGuests
                    numberOfRooms
                    pendingChangesSend
                    termsAccepted
                    logs {
                        id
                        createdAt
                        message
                        subject
                    }
                    reservationDate
                    state
                    guests {
                        id
                        name
                        surname
                        email
                        guestRole
                        stayGuestRooms {
                            checkedout
                            roomID
                            id
                            room {
                                id
                                name
                                number
                            }
                        }
                    }
                }
            }
        }
    `,
    defaultVars: { hotelRef: "", codes: [] },
};

const DEFAULT_QUERY_BODY = `info {
    count
    page
    size
}
results {
    code
    arrivalDate
    departureDate
    state
    numberOfRooms
    pendingChangesSend
    termsAccepted
    numberOfGuests
    guests {
        guestRole
        stayGuestRooms {
            checkedout
            roomID
            room {
                number
            }
        }
    }
}`;

export const GET_UPCOMING_BOOKINGS_WHIT_EMAIL = {
    id: "GET_UPCOMING_BOOKINGS",
    query: gql`
        query getStayBookings($hotelRef: ID!, $email: String) {
            getStayBookings(hotelRef: $hotelRef, filter: { mainGuestEmail: $email }) {
                ${DEFAULT_QUERY_BODY}
            }
        }
    `,
    defaultVars: null,
};

export const GET_UPCOMING_BOOKINGS_WHIT_CODES = {
    id: "GET_UPCOMING_BOOKINGS",
    query: gql`
        query getStayBookings($hotelRef: ID!, $codes: [String]) {
            getStayBookings(hotelRef: $hotelRef, filter: { codes: $codes }) {
                ${DEFAULT_QUERY_BODY}
            }
        }
    `,
    defaultVars: null,
};

export const GET_UPCOMING_BOOKINGS_WHIT_CODE_AND_EMAIL = {
    id: "GET_UPCOMING_BOOKINGS",
    query: gql`
        query getStayBookings($hotelRef: ID!,$email: String, $codes: [String]) {
            getStayBookings(hotelRef: $hotelRef, filter: { mainGuestEmail: $email,codes: $codes }) {
                ${DEFAULT_QUERY_BODY}
            }
        }
    `,
    defaultVars: null,
};

export const GET_PRECHECKIN_SETTINGS_STAY_BOOKINGS = {
    query: gql`
        query preCheckInSettingsAndGetStayBookings($hotelRef: ID!, $codes: [String]!) {
            getStayBookings: getStayBookings(hotelRef: $hotelRef, filter: { codes: $codes }, page: 1, size: 100) {
                info {
                    count
                    page
                    size
                }
                results {
                    id
                    code
                    arrivalDate
                    departureDate
                    numberOfGuests
                    pendingChangesSend
                    termsAccepted
                    numberOfRooms
                    observations
                    reservationDate
                    state
                    logs {
                        id
                        createdAt
                        message
                        subject
                    }
                    guests {
                        id
                        name
                        surname
                        email
                        guestRole
                        stayBookingRoomIndex
                        phoneNumber
                        countryRef
                        documentExtraInformation
                        documentType
                        documentNumber
                        gender
                        birthDate
                        stayGuestRooms {
                            checkedout
                            roomID
                            id
                            room {
                                id
                                name
                                number
                            }
                        }
                    }
                }
            }
            getPreCheckInSettings: getPreCheckInSettings(hotelRef: $hotelRef) {
                preCheckInSettings {
                    automaticSendingOfTheTravelersReport
                    enabled
                    maximumAgeOfChildren
                    requestDataFromChildren
                    sendEmailAssignRoom
                }
                preCheckInSettingsFields {
                    birthdate {
                        enabled
                        required
                    }
                    documentExpeditionDate {
                        enabled
                        required
                    }
                    documentNumber {
                        enabled
                        required
                    }
                    documentType {
                        enabled
                        required
                        values
                    }
                    email {
                        enabled
                        required
                    }
                    gender {
                        enabled
                        required
                    }
                    name {
                        enabled
                        required
                    }
                    nationality {
                        enabled
                        required
                    }
                    phone {
                        enabled
                        required
                    }
                    surname {
                        enabled
                        required
                    }
                }
                preCheckInSettingsFieldsChildren {
                    birthdate {
                        enabled
                        required
                    }
                    documentExpeditionDate {
                        enabled
                        required
                    }
                    documentNumber {
                        enabled
                        required
                    }
                    documentType {
                        enabled
                        required
                        values
                    }
                    email {
                        enabled
                        required
                    }
                    gender {
                        enabled
                        required
                    }
                    name {
                        enabled
                        required
                    }
                    nationality {
                        enabled
                        required
                    }
                    phone {
                        enabled
                        required
                    }
                    surname {
                        enabled
                        required
                    }
                }
            }
        }
    `,
    defaultVars: { hotelRef: "", codes: [] },
};

export const ADD_STAY_BOOKING_GUEST = {
    query: gql(`mutation addStayBookingGuest(
        $documentNumber: String,
        $documentType: NullableString,
        $documentExtraInformation: JSON,
        $stayBookingCode: String!,
        $surname: String,
        $stayBookingRoomIndex: String,
        $phoneNumber: String,
        $countryRef: CountryCode,
        $hotelRef: ID!,
        $gender: Gender,
        $name: String,
        $birthDate: Date,
        $email: String
    ) {
        addStayBookingGuest(
            documentNumber: $documentNumber,
            documentType: $documentType,
            documentExtraInformation: $documentExtraInformation,
            stayBookingCode: $stayBookingCode,
            surname: $surname,
            stayBookingRoomIndex: $stayBookingRoomIndex,
            phoneNumber: $phoneNumber,
            countryRef: $countryRef,
            hotelRef: $hotelRef,
            gender: $gender,
            name: $name,
            birthDate: $birthDate,
            email: $email
        ) {
            error
            id
            ok
        }
    }
    `),
    defaultVars: {
        hotelRef: "",
        stayBookingCode: "",
        documentNumber: "",
        documentType: "",
        documentExtraInformation: {},
        surname: "",
        stayBookingRoomIndex: "",
        phoneNumber: "",
        countryRef: "",
    },
};

export const UPDATE_STAY_BOOKING_GUEST = {
    query: gql(`mutation updateStayBookingGuest(
        $stayBookingCode: String!,
        $documentType: NullableString,
        $documentNumber: String,
        $gender: Gender,
        $hotelRef: ID!,
        $surname: String,
        $phoneNumber: String,
        $name: String,
        $stayBookingRoomIndex: String,
        $documentExtraInformation: JSON,
        $guestID: Int64!,
        $email: String,
        $birthDate: Date,
        $countryRef: CountryCode
    ) {
        updateStayBookingGuest(
            stayBookingCode: $stayBookingCode,
            documentType: $documentType,
            documentNumber: $documentNumber,
            gender: $gender,
            hotelRef: $hotelRef,
            surname: $surname,
            phoneNumber: $phoneNumber,
            name: $name,
            stayBookingRoomIndex: $stayBookingRoomIndex,
            documentExtraInformation: $documentExtraInformation,
            guestID: $guestID,
            email: $email,
            birthDate: $birthDate,
            countryRef: $countryRef
        ) {
            error
            id
            ok
        }
    }
    `),
    defaultVars: {
        hotelRef: "",
        stayBookingCode: "",
        documentNumber: "",
        documentType: "",
        documentExtraInformation: {},
        surname: "",
        stayBookingRoomIndex: "",
        phoneNumber: "",
        countryRef: "",
    },
};

export const SEND_STAY_BOOKING_PENDING_VALIDATION = {
    query: gql(`mutation sendStayBookingPendingValidation(
        $hotelRef: ID!,
        $code: String!
    ) {
        sendStayBookingPendingValidation(
            hotelRef: $hotelRef,
            code: $code
        ) {
            error
            ok
        }
    }
    `),
    defaultVars: {
        hotelRef: "",
        code: "",
    },
};

export const UPDATE_STAY_BOOKING = {
    query: gql`
        mutation updateStayBooking(
            $departureDate: Date
            $observations: String
            $code: String!
            $hotelRef: ID!
            $numberOfGuests: Int
            $termsAccepted: Boolean
            $pendingChangesSend: Boolean
            $numberOfRooms: Int
            $arrivalDate: Date
        ) {
            updateStayBooking(
                departureDate: $departureDate
                observations: $observations
                code: $code
                hotelRef: $hotelRef
                numberOfGuests: $numberOfGuests
                termsAccepted: $termsAccepted
                pendingChangesSend: $pendingChangesSend
                numberOfRooms: $numberOfRooms
                arrivalDate: $arrivalDate
            ) {
                error
                id
                ok
            }
        }
    `,
    defaultVars: {
        departureDate: null,
        observations: "",
        code: "",
        hotelRef: "",
        numberOfGuests: null,
        termsAccepted: null,
        pendingChangesSend: null,
        numberOfRooms: null,
        arrivalDate: null,
    },
};
