import React, { useEffect } from "react";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import swal from "@sweetalert/with-react";
import { nl2br } from "./Design/DesignUtils";
import { MESSAGE_ALERT_TYPES } from "../utils/messages";
import { getHotels } from "../actions/hotel";
import ShopOrder from "./ShopOrder";
import { ShopProvider } from "../contexts/shop";
import { useShops, useOrderInfo } from "graphql/shop";
import Loading from "./Loading";
import { displayTime } from "utils/shop";
import ErrorMessage from "./ErrorMessage";
import { useKeyDown } from "hooks/useKeyDown";

const TAG_SHOP_NAME = "ShopName";
const TAG_ORDER_PICKUP_PLACE = "PickUpPlace";
const TAG_ORDER_DELIVERY_TIME = "DeliveryHourAndDate";
const TAG_ORDER_SUMMARY = "OrderSummary";

const Message = (props) => {
    const { t, i18n } = useTranslation();
    const lang = (i18n.language || "").match(/^[^-]+/)[0];
    const projects = useSelector(getHotels);
    const message = props?.message;
    const onClose = props?.onClose;
    const pagination = props?.pagination;

    const shopToken = message?.information?.channelToken;
    const orderID = message?.information?.orderID;
    const projectRef = message?.projectRef;
    const roomNumber = message?.roomNumber;

    const {
        query: loadShop,
        called: loadShopCalled,
        loading: loadingShop,
        error: loadShopError,
        data: shop,
    } = useShops({ projectRef, shopToken });

    const {
        query: loadOrder,
        called: loadOrderCalled,
        loading: loadingOrder,
        error: loadOrderError,
        data: orderInfo,
    } = useOrderInfo({ shopToken, id: orderID });

    const dateInfo = message?.today
        ? t("today at", { hour: message?.hour })
        : t("{{date, date}}", { date: message?.sentTime });
    const projectName = (projects?.filter((item) => item.ref === projectRef) || [null])[0]?.name;
    const roomName = roomNumber ? t("room x", { room: roomNumber }) : null;
    const showBookmarked = MESSAGE_ALERT_TYPES.includes(message?.priority);
    const title = message?.title;

    const tagsFound = String(message?.body)
        .split(/(\{\{\.\w+\}\})/g)
        .map((part) => (part.match(/(\{\{\.\w+\}\})/g) ? part.replace("{{.", "").replace("}}", "") : null))
        .filter((part) => part !== null);

    const hasShopTags = tagsFound?.includes(TAG_SHOP_NAME);
    const hasOrderTags =
        tagsFound?.includes(TAG_ORDER_PICKUP_PLACE) ||
        tagsFound?.includes(TAG_ORDER_DELIVERY_TIME) ||
        tagsFound?.includes(TAG_ORDER_SUMMARY);

    useEffect(() => {
        if ((hasShopTags || hasOrderTags) && !loadShopCalled) {
            loadShop();
        }
        if (hasOrderTags && !loadOrderCalled) {
            loadOrder();
        }
    }, [hasOrderTags, hasShopTags, projectRef, shopToken]);

    const close = (force = false) => {
        if (!force && pagination && showBookmarked) {
            swal({
                title: t("close messages"),
                text: t("sure close messages"),
                buttons: {
                    confirm: t("close"),
                    cancel: t("cancel"),
                },
            }).then((confirm) => {
                if (confirm) {
                    if (onClose) {
                        onClose();
                    }
                    swal.close();
                } else {
                    swal.close();
                }
            });
        } else if (onClose) {
            onClose();
        }
    };

    const loading = loadingShop || loadingOrder;
    const error = loadShopError || loadOrderError;

    const errorTag = <span className="text-red-100">{String.fromCharCode(0x26a0)}</span>;

    const body =
        tagsFound?.lentgh === 0
            ? message?.body
            : String(message?.body)
                  .split(/(\{\{\.\w+\}\})/g)
                  .map((part) => {
                      if (part.match(/(\{\{\.\w+\}\})/g)) {
                          const key = part.replace("{{.", "").replace("}}", "");
                          switch (key) {
                              case TAG_SHOP_NAME:
                                  return shop ? shop.name : errorTag;
                              case TAG_ORDER_PICKUP_PLACE:
                                  return orderInfo ? orderInfo.deliveryLocation : errorTag;
                              case TAG_ORDER_DELIVERY_TIME:
                                  if (orderInfo?.scheduleStart) {
                                      if (orderInfo?.scheduleEnd) {
                                          const start = new Date(orderInfo?.scheduleStart);
                                          const end = new Date(orderInfo?.scheduleEnd);
                                          return (
                                              displayTime(start.getHours(), start.getMinutes(), { lang }) +
                                              " - " +
                                              displayTime(end.getHours(), end.getMinutes(), { lang })
                                          );
                                      } else {
                                          return t("as soon as possible");
                                      }
                                  }
                                  return errorTag;
                              case TAG_ORDER_SUMMARY:
                                  return (
                                      <ShopProvider
                                          key={`msg_${message?.id}`}
                                          value={{
                                              shopToken,
                                              pricesIncludeTax: shop?.pricesIncludeTax,
                                              deliverySchedule: shop?.deliverySchedule,
                                              autoAcceptOrders: shop?.autoAcceptOrders,
                                              theme: null,
                                          }}
                                      >
                                          <ShopOrder
                                              lang={lang}
                                              orderID={orderID}
                                              isMessage={true}
                                              orderInfo={orderInfo}
                                              style={{ zoom: "0.9" }}
                                          />
                                      </ShopProvider>
                                  );
                              default:
                                  return <span className="text-red-100">{part}</span>;
                          }
                      }
                      return nl2br(part);
                  });

    useKeyDown({
        keys: ["Escape"],
        callback: () => {
            close(true);
        },
    });

    useKeyDown({
        keys: ["ArrowLeft", "ArrowRight"],
        callback: (e) => {
            if (pagination) {
                switch (e?.key) {
                    case "ArrowLeft":
                        if (pagination.current > 1) {
                            pagination.prev();
                        }
                        break;
                    case "ArrowRight":
                        if (pagination.current === pagination.total) {
                            close();
                        } else {
                            pagination.next();
                        }
                }
            }
        },
    });

    const closeEnabled = !(pagination && pagination.current !== pagination.total);

    return (
        <div className="fixed grid place-items-center z-201 w-full h-full top-0 left-0 bottom-0 right-0 bg-black bg-opacity-75">
            <div className="bg-opacity-100 px-10 w-full">
                <div
                    className="relative w-full border shadow-md text-center px-5 pt-5 pb-24 my-2 bg-neutral rounded-lg overflow-y-auto"
                    style={pagination || loading ? { height: `90vh` } : { maxHeight: `90vh` }}
                >
                    {loading ? (
                        <Loading />
                    ) : (
                        <>
                            <div className="flex w-full justify-between w-full text-left mb-2">
                                <div>
                                    <div>{projectName}</div>
                                    {roomName ? (
                                        <div className="text-sm text-neutral-contrast-50">{roomName}</div>
                                    ) : null}
                                </div>
                                {dateInfo ? (
                                    <div className="date text-sm text-neutral-contrast-50">{dateInfo}</div>
                                ) : null}
                            </div>
                            {title ? (
                                <div className="w-full text-left my-2">
                                    {showBookmarked ? <i className="icon icon-union text-yellow-500 mr-1"></i> : null}
                                    <span className="font-bold">{title}</span>
                                </div>
                            ) : null}
                            {body || error ? (
                                <div
                                    className="w-full text-left overflow-y-auto my-2"
                                    style={{ maxHeight: `calc( 90vh - 12rem )` }}
                                >
                                    {error ? <ErrorMessage type="connection" error={error} /> : null}
                                    {body}
                                </div>
                            ) : null}
                            <div
                                className="p-5 absolute bg-white left-0 right-0 bottom-0"
                                style={{
                                    boxShadow: "0 -.2em 1em rgba(0,0,0,0.05)",
                                }}
                            >
                                {pagination ? (
                                    <div className="flex justify-between w-full mb-5">
                                        <span>
                                            {pagination.current > 1 ? (
                                                <i
                                                    className="icon icon-back text-xl cursor-pointer text-accent"
                                                    onClick={pagination.prev}
                                                ></i>
                                            ) : (
                                                <i className="text-xl"></i>
                                            )}
                                        </span>
                                        <span className="text-neutral-contrast-50">
                                            {pagination.current}/{pagination.total}
                                        </span>
                                        {pagination.current !== pagination.total ? (
                                            <span className="transform rotate-180">
                                                <i
                                                    className="icon icon-back text-xl cursor-pointer text-accent"
                                                    onClick={pagination.next}
                                                ></i>
                                            </span>
                                        ) : null}
                                    </div>
                                ) : null}
                                <button
                                    className="btn btn-accent w-full"
                                    disabled={!closeEnabled}
                                    style={{ opacity: closeEnabled ? 1 : 0.5 }}
                                    onClick={() => {
                                        if (closeEnabled) {
                                            close();
                                        }
                                    }}
                                >
                                    {t("close")}
                                </button>
                            </div>
                        </>
                    )}
                </div>
            </div>
        </div>
    );
};

export default Message;
