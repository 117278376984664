import { useState } from "react";
import { useSelector } from "react-redux";
import { getSelectedHotel } from "../actions/hotel";
import useAddRoom from "./useAddRoom";

const useReaderScan = (config) => {
    const [devices, setDevices] = useState([]);
    const [legacyMode, setLegacyMode] = useState(false);
    const [scanning, setScanning] = useState(false);
    const [currentDevice, setCurrentDevice] = useState("");
    const project = useSelector(getSelectedHotel);

    const { addRooms } = useAddRoom({ project });

    const getURL = () => (config && config.getURL ? config.getURL() : null);

    const notFoundCamera = () => {
        if (!legacyMode) {
            setLegacyMode(true);
        }
    };

    const parseScan = async (data) => {
        if (scanning) return;
        const url = getURL();
        if (!url) {
            console.error("not found url");
            if (config && config.onError) {
                config.onError("NOT_FOUND_URL");
            }
            return;
        }

        if (!data || data === "") {
            console.error("not found data");
            if (config && config.onError) {
                config.onError("NOT_FOUND_DATA");
            }
            return;
        }

        setScanning(true);
        console.info(data);
        let room = null;
        let code = null;
        const urlResponse = new URL(data);
        const queryParams = new URLSearchParams(urlResponse.search);
        if (queryParams.get("room")) {
            room = queryParams.get("room");
        }
        if (queryParams.get("code")) {
            code = queryParams.get("code");
        }

        if (room && code) {
            await addRooms([{ number: room, code: code }], { check: true, checkWiFi: true })
                .then((success) => {
                    if (config && config.onSuccess) {
                        config.onSuccess(success ? room : null);
                    }
                })
                .catch((e) => {
                    console.error(e);
                    if (config && config.onError) {
                        config.onError("INVALID_QR");
                    }
                });
        } else {
            console.error("not found room and code");
            if (config && config.onError) {
                config.onError("INVALID_QR");
            }
        }
    };

    const onReaderScan = (e) => {
        if (e || legacyMode) {
            parseScan(e);
        }
    };

    const onReaderError = (error) => {
        console.error(error);
        notFoundCamera();
    };

    const onReaderLoad = () => {
        if (!legacyMode && navigator && navigator.mediaDevices) {
            navigator.mediaDevices
                .enumerateDevices()
                .then((devices) => {
                    let videoDevices = [];
                    if (devices && devices.length > 0) {
                        videoDevices = devices.filter(function (device) {
                            return device.kind === "videoinput";
                        });
                    }
                    if (videoDevices && videoDevices.length > 0) {
                        setDevices(videoDevices);
                        if (currentDevice === "") {
                            setFirstDevice(videoDevices);
                        }
                    } else {
                        notFoundCamera();
                    }
                })
                ["catch"]((e) => {
                    notFoundCamera();
                    console.error(e);
                });
        } else {
            notFoundCamera();
        }
    };

    const setFirstDevice = (filteredDevices) => {
        if (!filteredDevices || filteredDevices.length <= 0) {
            filteredDevices = devices;
        }
        if (currentDevice === "" && filteredDevices && filteredDevices.length > 0) {
            if (filteredDevices[0]) {
                const id = filteredDevices[0].deviceId;
                setCurrentDevice(id);
                return id;
            }
        }
        return null;
    };

    const onReaderChooseDevice = (filteredDevices) => {
        return setFirstDevice(filteredDevices);
    };

    const stopScan = () => {
        setScanning(false);
    };

    const toggleDevice = () => {
        const first = getDeviceID(0);
        const second = getDeviceID(1);
        const set = currentDevice === second ? first : second;
        setCurrentDevice(set);
        return set;
    };

    const getDeviceID = (index) => {
        return devices && devices[index] ? devices[index].deviceId : null;
    };

    return {
        onReaderScan,
        onReaderLoad,
        onReaderError,
        onReaderChooseDevice,
        legacyMode,
        devices,
        stopScan,
        currentDevice,
        setCurrentDevice,
        toggleDevice,
    };
};

export default useReaderScan;
