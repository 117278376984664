import React, { useContext, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { ORDER_STATE_CANCELLED, printPrice } from "../utils/shop";
import { size as renderSize } from "zrender/sizes";
import { getDeviceWidth } from "./Design/DesignUtils";
import Tag from "./Tag";
import QuantityButton from "./QuantityButton";
import { ShopContext } from "../contexts/shop";
import { useShopExtras } from "../graphql/shop";

const ProductItem = ({
    keyID,
    index,
    id: productID,
    cartItemID,
    isMessage,
    lang,
    style,
    name,
    description,
    coverURL,
    price,
    currency,
    tags,
    order,
    onClick,
    editable = true,
    orderState,
    confirmRemove,
    maxUnitsPerOrder,
}) => {
    const size = (v) => renderSize(v, "mobile", getDeviceWidth());

    const { t: tr } = useTranslation();
    const t = (label, data) => {
        return tr(label, { lng: lang, ...(data || {}) });
    };

    const { shop, cart, updateCartQuantity, addToCart, setProductID, setCartItemID, maxProductsPerOrder } =
        useContext(ShopContext);

    const [prepareQuantity, setPrepareQuantity] = useState(null);

    const {
        query: loadExtras,
        called: loadExtrasCalled,
        loading: loadingExtras,
        error: loadExtrasError,
        data: extras,
    } = useShopExtras({ shopToken: shop?.token, productID });

    const inCart = (() => {
        if (cartItemID) {
            return cart?.products?.[cartItemID - 1];
        } else {
            const items = cart?.products?.filter((p) => p?.productID === productID);
            if (items?.length > 0) {
                return items;
            }
        }
        return null;
    })();
    const inCartSumQuantity = cartItemID ? inCart?.quantity : inCart?.reduce((sum, p) => sum + p?.quantity, 0);
    const itemID =
        cartItemID || (inCart?.length === 1 ? cart?.products?.findIndex((p) => p?.productID === productID) + 1 : null);
    const inCartQuantity =
        (() => {
            if (order) {
                return order?.quantity;
            } else if (itemID) {
                return cart?.products?.[itemID - 1]?.quantity;
            }
            return inCartSumQuantity;
        })() || 0;
    const initialQuantity = inCartQuantity || 0;

    const countExtras = (() => {
        if (order) {
            return Object.keys(order?.extras || {}).length;
        } else if (cartItemID) {
            return Object.keys(inCart?.selections || {}).length;
        }
        return 0;
    })();

    const [cartUnits, setCartUnits] = useState(initialQuantity);

    const maxSizeDesc = 90 - (cartUnits > 0 ? 3 : 0) - (coverURL ? 30 : 0);
    const desc =
        description?.length > maxSizeDesc ? description.trim().substring(0, maxSizeDesc) + "..." : description?.trim();

    const maxSizeTags = 24 + (cartUnits > 0 ? 0 : 6) + (coverURL && !desc ? 0 : 20);
    let sumTagsLength = 0;
    let tagsLimited = false;
    const filteredTags = tags?.filter((tag) => {
        const tagSize = tag.length + 4;
        if (sumTagsLength + tagSize > maxSizeTags) {
            tagsLimited = true;
            return false;
        }
        sumTagsLength += tagSize;
        return true;
    });
    if (tagsLimited) {
        filteredTags.push("...");
    }

    useEffect(() => {
        if (itemID) {
            updateCartQuantity(itemID, cartUnits);
        } else if (loadExtrasCalled && !loadingExtras) {
            if (itemID) {
                updateCartQuantity(itemID, cartUnits);
            } else if (cartUnits > 0) {
                addToCart({
                    productID,
                    quantity: cartUnits,
                    name,
                    price,
                    maxUnitsPerOrder,
                });
            }
        }
    }, [cartUnits]);

    useEffect(() => {
        if (inCartQuantity != cartUnits) {
            setCartUnits(inCartQuantity);
        }
    }, [inCartQuantity]);

    useEffect(() => {
        if (prepareQuantity && loadExtrasCalled && !loadingExtras) {
            setPrepareQuantity(null);
            itemRunAction(prepareQuantity);
        }
    }, [extras]);

    const styles = {
        global: {
            fontSize: size(1.4),
            paddingTop: size(1.5),
            paddingBottom: size(1.5),
            paddingLeft: size(1.5),
            paddingRight: size(1.5),
            color: "#323232",
            backgroundColor: "#FBFBFB",
            ...style?.global,
        },
        tags: {
            marginTop: size(1.05),
            ...style?.tags,
        },
        tag: style?.tag,
        qtyTag: {
            ...style?.tag,
            marginRight: size(1),
        },
    };

    const listTags = filteredTags?.length ? (
        <div style={styles.tags}>
            {filteredTags?.map((tag) => (
                <Tag key={`tag-${tag}`} style={styles.tag}>
                    {tag}
                </Tag>
            ))}
        </div>
    ) : null;

    const itemRunAction = (units) => {
        if (!itemID && (inCart?.length > 1 || extras?.length > 0)) {
            setProductID(productID);
            setCartItemID(itemID);
        } else {
            setCartUnits(units);
        }
    };

    const quantityHandler = (newCartUnits) => {
        if (itemID) {
            itemRunAction(newCartUnits);
        } else if (!loadExtrasCalled) {
            setPrepareQuantity(newCartUnits);
            loadExtras();
        } else if (loadingExtras) {
            setPrepareQuantity(newCartUnits);
        } else {
            itemRunAction(newCartUnits);
        }
    };

    useEffect(() => {
        if (loadExtrasError) {
            console.error(loadExtrasError);
        }
    }, [loadExtrasError]);

    const nameOutside = ((order || cartItemID) && countExtras) || coverURL || desc;

    const finalPrice = (() => {
        let sum = price;
        if (order) {
            sum = price * cartUnits;
        } else if (cartItemID) {
            const totalExtras = Object.keys(inCart?.selections || {}).reduce(
                (a, b) => a + inCart?.selections[b]?.price * inCart?.selections[b]?.quantity,
                0
            );
            sum = (price + totalExtras) * cartUnits;
        }
        return sum;
    })();

    const printExtras = (selections) => {
        return (
            <>
                {Object.keys(selections || {})?.map((extraID) => {
                    const extra = selections?.[extraID];
                    if (extra?.selections && Object.keys(extra?.selections).length > 0) {
                        return printExtras(extra?.selections);
                    } else {
                        return (
                            <div key={`extra_${extraID}`}>
                                {orderState !== ORDER_STATE_CANCELLED && `${extra?.quantity}x`}
                                {extra?.name || extraID}
                            </div>
                        );
                    }
                })}
            </>
        );
    };

    // Sum of units in cart for the current product
    const sumProductUnitsInOrder = inCartSumQuantity || 0;
    // Sum of units in cart, except the current product
    const sumOtherUnitsInOrder =
        (cart?.products?.reduce((sum, p) => sum + p?.quantity, 0) || 0) - (sumProductUnitsInOrder || 0);
    // Limit of units per order (current product)
    const maxProductUnitsPerOrder = maxUnitsPerOrder || 0;
    // Limit of units per order (all products)
    const maxAllUnitsPerOrder = maxProductsPerOrder || 0;
    // Determine max units
    const orderLimitToAdd =
        maxAllUnitsPerOrder > 0 ? maxAllUnitsPerOrder - sumOtherUnitsInOrder - sumProductUnitsInOrder : null;
    const productLimitToAdd =
        maxProductUnitsPerOrder > 0 ? maxProductUnitsPerOrder - (sumProductUnitsInOrder || 0) : null;
    let maxUnitsToAdd = null;
    if (productLimitToAdd !== null && orderLimitToAdd !== null) {
        maxUnitsToAdd = Math.min(productLimitToAdd, orderLimitToAdd);
    } else if (productLimitToAdd !== null) {
        maxUnitsToAdd = productLimitToAdd;
    } else if (orderLimitToAdd !== null) {
        maxUnitsToAdd = orderLimitToAdd;
    }

    const showDelete = confirmRemove && inCart && !loadingExtras && cartUnits === 1;

    const minValue = inCart ? 0 : Math.min(1, maxUnitsToAdd === null ? 1 : maxUnitsToAdd);
    const maxValue = inCart && maxUnitsToAdd !== null ? maxUnitsToAdd + inCartQuantity : maxUnitsToAdd;

    return (
        <div
            id={`product-${index + 1}`}
            key={keyID}
            onClick={onClick}
            target="details"
            style={{
                marginBottom: size(0.25),
                ...(onClick ? { cursor: "pointer" } : null),
            }}
        >
            <div className="flex justify-between items-start" style={styles.global}>
                {isMessage && cartUnits > 0 ? <div className="pr-1">{`${cartUnits}x`}</div> : null}
                {!isMessage && cartUnits > 0 ? <Tag style={styles.qtyTag}>{`x${cartUnits}`}</Tag> : null}
                <div className="flex-auto">
                    {nameOutside ? name : null}
                    <div
                        className="flex justify-between"
                        style={{ paddingTop: nameOutside ? size(0.5) : 0, paddingBottom: size(0.5) }}
                    >
                        <div className="flex">
                            {coverURL ? (
                                <div
                                    className="flex-none rounded text-center"
                                    style={{
                                        width: size(6.2),
                                        borderRadius: size(1),
                                        height: size(6.2),
                                        backgroundSize: "cover",
                                        backgroundPosition: "center",
                                        backgroundRepeat: "no-repeat",
                                        ...(coverURL ? { backgroundImage: `url("${coverURL}")` } : null),
                                    }}
                                ></div>
                            ) : null}
                            <div
                                className="flex justify-between flex-col"
                                style={{
                                    paddingTop: 0,
                                    paddingBottom: 0,
                                    paddingLeft: coverURL ? size(1) : 0,
                                    paddingRight: size(1),
                                }}
                            >
                                {!nameOutside ? name : null}
                                {desc ? (
                                    <div
                                        style={{
                                            fontSize: size(1.25),
                                            color: "rgb(118,136,155)",
                                        }}
                                    >
                                        {desc}
                                    </div>
                                ) : null}
                                {(order || cartItemID) && countExtras ? (
                                    <div
                                        style={
                                            isMessage
                                                ? null
                                                : {
                                                      fontSize: size(1.25),
                                                      color: "rgb(118,136,155)",
                                                  }
                                        }
                                    >
                                        <div>
                                            <strong>{t("extras")}</strong>
                                        </div>
                                        {printExtras(order ? order?.extras : inCart?.selections)}
                                    </div>
                                ) : null}
                                {!isMessage && !isNaN(finalPrice) ? (
                                    <div className="font-bold" style={{ marginTop: size(0.7) }}>
                                        {printPrice(finalPrice, currency, lang)}
                                    </div>
                                ) : null}
                                {desc ? null : listTags}
                            </div>
                        </div>
                        {editable ? (
                            <div
                                style={{
                                    color: "#2E3843",
                                    display: "flex",
                                    ...(showDelete
                                        ? {
                                              flexDirection: "column",
                                              justifyContent: "space-between",
                                              alignItems: "flex-end",
                                          }
                                        : null),
                                }}
                            >
                                <div
                                    id={`quantity-container-${keyID}`}
                                    onClick={(e) => {
                                        e.stopPropagation();
                                    }}
                                    style={{
                                        display: "grid",
                                        justifyContent: "space-around",
                                        alignItems: "stretch",
                                        flexGrow: 1,
                                    }}
                                >
                                    <QuantityButton
                                        id={`product-${index + 1}-quantity-add`}
                                        type="add"
                                        min={minValue}
                                        max={maxValue}
                                        value={cartUnits}
                                        onNewValue={quantityHandler}
                                        disabled={loadingExtras}
                                        loading={loadingExtras}
                                        style={{ borderColor: "#D3DAE1" }}
                                    />
                                    {cartUnits > (showDelete ? 1 : 0) ? (
                                        <QuantityButton
                                            id={`product-${index + 1}-quantity-remove`}
                                            type="remove"
                                            value={cartUnits}
                                            disabled={loadingExtras || cartUnits <= (showDelete ? 1 : 0)}
                                            loading={loadingExtras}
                                            onNewValue={quantityHandler}
                                            style={{
                                                borderColor: "#D3DAE1",
                                                marginTop: size(0.75),
                                            }}
                                        />
                                    ) : null}
                                </div>
                                {showDelete ? (
                                    <div
                                        id={`product-${index + 1}-remove`}
                                        className="font-bold cursor-pointer pt-4"
                                        onClick={(e) => {
                                            e.stopPropagation();
                                            setCartUnits(0);
                                        }}
                                    >
                                        {t("delete")}
                                    </div>
                                ) : null}
                            </div>
                        ) : null}
                    </div>
                    {desc ? listTags : null}
                </div>
            </div>
        </div>
    );
};

export default ProductItem;
